<template>
    <div v-if="getSavedList" class="flex">
        <the-navbar></the-navbar>
        <div class="flex flex-col w-full h-dvh relative p-5 lg:px-8 lg:py-10 h-screen overflow-auto">
            <h1 class="my-3 mb-4 text-center md:text-left text-lg lg:text-2xl font-bold">Favoriete video's:</h1>
            <div class="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 my-4 gap-3 lg:gap-8">
                <the-thumbnail-comp v-for="video in getVideos" :key="video" :video="video"></the-thumbnail-comp>
            </div>
            <div v-if="getSavedList.length === 0" class="rounded-2xl w-fit text-center sm:text-left">
                <p class="font-medium">Geen opgeslagen video's</p>
                <p class="text-sm max-w-lg text-neutral-500 mt-2">Je kunt video's opslaan door op het <fa-icon
                        icon="heart" class="mx-1" />
                    icoon te
                    klikken in de
                    videospeler. Daarna is je opgeslagen video op deze pagina terug te vinden.
                </p>
            </div>
        </div>
        <the-legend-comp></the-legend-comp>
    </div>
</template>

<script>
import TheNavbar from '@/components/TheNavbar.vue';
import TheThumbnailComp from '@/components/TheThumbnailComp.vue';
import TheLegendComp from '@/components/TheLegendComp.vue';

export default {
    components: { TheNavbar, TheThumbnailComp, TheLegendComp },
    computed: {
        // Comparing videoIndex to saved video's from userData, and returning saved video data
        getVideos() {
            let videoIndex = this.$store.getters.getVideoIndex
            let favorites = this.getSavedList
            let result = []

            favorites.forEach(favo => {
                let favoArray = favo.split('/')
                let category = favoArray[0]
                let id = favoArray[1]

                let catFromIndex = videoIndex.find(cat => cat.id == category)
                if (catFromIndex) { result.push(catFromIndex.videos[id]) }

            });

            return result
        },
        // Loading saved video references from userData
        getSavedList() {
            let userData = this.$store.getters.getUserData
            let savedVideos = []
            if (userData) { savedVideos = userData.savedVideos }

            return savedVideos
        },
    },
    mounted() {
        document.title = "T'ai Chi voor thuis - Mijn favorieten";
    }
}
</script>