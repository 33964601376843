import { createRouter, createWebHistory } from "vue-router";
import { projectAuth } from "../../firebaseConfig";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import LogoutView from "../views/LogoutView.vue";
import VideoPlayerView from "../views/VideoPlayerView.vue";
import AccountView from "../views/AccountView.vue";
import UsersView from "../views/UsersView.vue";
import SavedView from "../views/SavedView.vue";
import ContactView from "../views/ContactView.vue";
import LandingView from "../views/LandingView.vue";
import RequestSubView from "../views/RequestSubView.vue";
import PageNotFoundView from "../views/PageNotFoundView.vue"

const routes = [
  {
    path: "/home",
    name: "home",
    component: HomeView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: LogoutView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/play/:category/:id",
    name: "player",
    component: VideoPlayerView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account",
    name: "account",
    component: AccountView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users",
    name: "users",
    component: UsersView,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: "/saved",
    name: "saved",
    component: SavedView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/",
    name: "landing",
    component: LandingView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/request-subscription",
    name: "request-subscription",
    component: RequestSubView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: PageNotFoundView,
    meta: {
      requiresAuth: false,
    },
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, next) => {
  const reqAuth = to.matched.some((record) => record.meta.requiresAuth);

  /* checking if route requires auth, if so check if user is present, otherwise route to /login */

  if (reqAuth) {
    projectAuth.onAuthStateChanged((user) => {
      if (user) {
        next;
      } else {
        router.push("/login");
      }
    });
  }

  /* If a user is logged in, redirects login screen to homescreen */

  if (to.fullPath == "/login" || to.fullPath == "/") {
    projectAuth.onAuthStateChanged((user) => {
      if (user) {
        router.push("/home");
      } else {
        next;
      }
    });
  } else {
    next;
  }
});

export default router;
