<template>
    <div class="h-dvh w-screen fixed z-20 flex items-end md:items-center justify-center">
        <div class="wall bg-black/50 z-20 w-full h-full absolute cursor-pointer" @click="$emit('close')"></div>
        <div class="bg-white rounded-t-2xl md:rounded-2xl p-5 sm:p-7 max-w-md flex flex-col z-30 drop-shadow-xl">
            <div>
                <div class="flex items-center justify-between mb-3">

                    <h2 class="font-semibold text-lg"><fa-icon icon="arrows-rotate" class="mr-2" />Lidmaatschap
                        vernieuwen</h2>
                    <fa-icon @click="$emit('close')" icon="times" class="text-lg cursor-pointer" />
                </div>
                <p class="text-sm text-neutral-500 pt-2 leading-6">
                    Weet je zeker dat je het lidmaatschap wilt vernieuwen van: <span
                        class="font-semibold text-green-800 bg-green-600/10 p-0.5 px-2 rounded-full text-nowrap">
                        {{ user.name + ' ' + user.surname }}
                    </span> ? Als deze gebruiker nog resterende dagen over heeft zullen deze toegevoegd
                    worden aan de nieuwe looptijd.</p>

                <div class="text-sm flex items-center gap-2 mt-5 mb-7 rounded-2xl ">

                    <div class="inline-flex items-center cursor-pointer">
                        <label class="relative flex items-center rounded-full cursor-pointer" htmlFor="customStyle">
                            <input type="checkbox" v-model="confirmChecked"
                                class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-gray-500 bg-white transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-green-600 checked:bg-green-600 checked:before:bg-gray-900 hover:scale-105 hover:before:opacity-0"
                                id="customStyle" />
                            <span
                                class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20"
                                    fill="currentColor" stroke="currentColor" stroke-width="1">
                                    <path fill-rule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clip-rule="evenodd"></path>
                                </svg>
                            </span>
                        </label>
                        <p @click="confirmChecked = !confirmChecked" class="ml-2">Ik bevestig dat deze klant beetaald
                            heeft.</p>
                    </div>

                </div>
                <div class="grid grid-cols-2 gap-4">

                    <button @click="$emit('close')"
                        class="col-span-1  py-3 px-6 rounded-lg mb-2 border border-neutral-500 text-neutral-700 font-semibold text-sm hover:bg-neutral-700 hover:text-white transition-colors">Annuleren</button>
                    <button @click="renewUser" :disabled="!confirmChecked"
                        class="col-span-1  py-3 px-6 rounded-lg mb-2 bg-green-600 text-white font-semibold text-sm hover:bg-green-700 transition-colors disabled:opacity-20 disabled:hover:bg-green-600">Vernieuwen</button>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { projectDb } from '../../../firebaseConfig.js';
import { addDays, differenceInDays, fromUnixTime } from 'date-fns';

export default {
    props: ['user'],
    emits: ['close'],
    data() {
        return {
            confirmChecked: false,
        }
    },
    methods: {
        async renewUser() {
            this.confirmChecked = false
            let uid = this.user.uid
            var userRef = projectDb.collection('users').doc(uid)
            var expRef = projectDb.collection('exp_dates').doc(uid)
            let oldEndDate = null

            await expRef.get().then((doc) => {
                const data = doc.data()
                oldEndDate = fromUnixTime(data.endDate.seconds)
            })

            // Checking weither the user still has days left, and adding them to the new subscription term if so.
            var difference = differenceInDays(oldEndDate, new Date)
            var daysToAdd = 365

            if (difference > 0) {
                daysToAdd = daysToAdd + difference;
            }

            const newEndDate = addDays(new Date, daysToAdd)

            await expRef.update({
                'renewDate': new Date,
                'endDate': newEndDate
            })

            await userRef.update({
                'renewDate': new Date,
                'endDate': newEndDate
            })

            this.$emit('closeAndReload');
        }
    }
}
</script>
