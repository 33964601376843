<template>
    <div
        class="w-screen h-screen  sm:flex flex-col justify-center sm:flex-row items-center bg-[url('../assets/wall.jpg')] bg-cover bg-center">
        <div class="sm:hidden h-1/2 w-full flex items-center justify-center bg-[url('../assets/wall.jpg')] bg-cover">
            <img src="../assets/logo.png" alt="tai chi chuan nijmegen logo" class="sm:hidden h-24 drop-shadow-xl">
        </div>
        <form @submit.prevent="handleFormSubmit"
            class="bg-white rounded-2xl p-6 sm:p-6 sm:px-8 flex text-center flex-col gap-3 justify-center w-full h-1/2 sm:h-fit sm:w-fit drop-shadow-lg">
            <img src="../assets/logo.png" alt="tai chi chuan nijmegen logo"
                class="hidden sm:block w-40 self-center pt-4 pb-6">
            <h3 class="font-bold mb-4">De Kleine Weg - Tai Chi voor Thuis</h3>

            <input type="text" name="email" id="email"
                class="bg-neutral-50 border border-neutral-200 py-3.5 px-6 rounded-2xl text-sm mb-3 font-normal placeholder-neutral-400 focus:outline-green-600"
                placeholder="E-mail adres" v-model="loginCreds.email" required>
            <div v-if="!resetPassMode" class="w-full relative flex flex-col justify-center mb-3">
                <input :type="passVisible ? 'text' : 'password'" name="password" id="password"
                    class="bg-neutral-50 border w-full border-neutral-200 py-3.5 px-6 rounded-2xl text-sm placeholder-neutral-400 focus:outline-green-600"
                    placeholder="Wachtwoord" v-model="loginCreds.password" required>
                <fa-icon v-if="passVisible" @click="passVisible = !passVisible" icon="eye-slash"
                    class="absolute right-6 text-neutral-400 cursor-pointer" />
                <fa-icon v-else @click="passVisible = !passVisible" icon="eye"
                    class="absolute right-6 text-neutral-400 cursor-pointer" />
            </div>
            <button class="bg-green-600 py-3 px-6 rounded-2xl text-white hover:bg-green-700 transition-colors mb-3">{{
            getBtnTxt }}</button>
            <p v-for="error in errorMessages" :key="error" class="col-span-2 text-xs text-center text-red-600">
                {{ error }}
            </p>
            <a v-if="resetPassMode" class="text-sm underline text-green-800"
                @click="resetPassMode = !resetPassMode">Terug
                naar
                inloggen</a>
            <a v-else class="text-sm underline  text-green-800 cursor-pointer"
                @click="resetPassMode = !resetPassMode">Wachtwoord
                vergeten?</a>
        </form>
    </div>
</template>
<script>
import { projectAuth, projectDb } from '../../firebaseConfig.js';


export default {
    data() {
        return {
            loginCreds: {
                email: '',
                password: ''
            },
            resetPassMode: false,
            falseInput: false,
            passVisible: false,
            errorMessages: []
        }
    },
    computed: {
        getBtnTxt() {
            return this.resetPassMode ? 'Wachtwoord herstellen' : 'Inloggen'
        }
    },
    methods: {
        submitLogin() {
            projectAuth.signInWithEmailAndPassword(this.loginCreds.email, this.loginCreds.password)
                .then((result) => {
                    const uid = result.user.uid

                    projectDb
                        .collection("users")
                        .doc(uid)
                        .get()
                        .then((doc) => {
                            if (doc.exists) {
                                this.$router.push('/home')
                            }
                        });

                })
                .catch(error => {
                    switch (error.code) {
                        case 'auth/invalid-email':
                            this.errorMessages.push('Ongeldig e-mailadres.')
                            break;
                        case 'auth/operation-not-allowed':
                            this.errorMessages.push('Handeling niet toegestaan.')
                            break;
                        case 'auth/missing-email':
                            this.errorMessages.push('Geen e-mailadres ingevoerd.')
                            break;
                        case "auth/invalid-credential":
                            this.errorMessages.push('Controleer de inloggegevens en probeer opnieuw.')
                            break;
                        default:
                            this.errorMessages.push(error.message)
                            break;
                    }
                })
        },
        submitResetPass() {
            projectAuth.sendPasswordResetEmail(this.loginCreds.email)
                .then(() => {
                    this.resetPassMode = false;
                })
                .catch((error) => {
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    console.log(errorCode, errorMessage);
                });
        },
        handleFormSubmit() {
            if (this.resetPassMode) {
                this.submitResetPass()
            } else {
                this.submitLogin()
            }
        }
    },
    mounted() {
        document.title = "T'ai Chi voor thuis - Login";
    },
};


</script>
<style lang="">

</style>