<template>
    <div class="h-dvh w-screen fixed z-20 flex items-end md:items-center justify-center">
        <div class="wall bg-black/50 z-20 w-full h-full absolute cursor-pointer" @click="$emit('close')"></div>
        <div class="bg-white rounded-t-2xl md:rounded-2xl p-5 sm:p-7 max-w-md flex flex-col z-30 drop-shadow-xl">
            <div>
                <div class="flex items-center justify-between mb-3">
                    <h2 class="font-semibold text-lg">Nieuwe gebruiker toevoegen</h2>
                    <fa-icon @click="$emit('close')" icon="times" class="text-lg cursor-pointer" />
                </div>
                <p class="text-sm text-neutral-500 mb-6">Vul de onderstaande gegevens in om een nieuwe
                    gebruiker toe te
                    voegen.
                    Er zal een email verstuurd worden
                    naar het ingevulde e-mailadres, waarmee de nieuwe gebruiker een wachtwoord kan aanmaken</p>
                <div class="grid grid-cols-2 gap-4">
                    <input type="text"
                        class="bg-neutral-50 py-3 px-6 rounded-lg border border-neutral-200 col-span-1 mb-2 text-sm focus:outline-green-600 placeholder:neutral-500"
                        placeholder="Voornaam" v-model="name" />
                    <input type="text"
                        class="bg-neutral-50 py-3 px-6 rounded-lg border border-neutral-200 col-span-1 mb-2 text-sm focus:outline-green-600 placeholder:neutral-500"
                        placeholder="Achternaam" v-model="surname" />
                    <input type="email"
                        class="bg-neutral-50 py-3 px-6 rounded-lg border border-neutral-200 col-span-2 mb-2 text-sm focus:outline-green-600 placeholder:neutral-500"
                        placeholder="e-mailadres" v-model="email" />
                    <button @click="submitNewUser"
                        class="col-span-2  py-3 px-6 rounded-lg mb-2 bg-green-600 hover:bg-green-700 transition-colors text-white font-semibold text-sm">Gebruiker
                        toevoegen</button>
                    <p v-for="error in errorMessages" :key="error" class="col-span-2 text-xs text-center text-red-600">
                        {{ error }}
                    </p>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { addDays } from 'date-fns';
import { projectDb, secondaryFb } from '../../../firebaseConfig.js';

export default {
    emits: ['close', 'closeAndReload'],
    data() {
        return {
            name: '',
            surname: '',
            email: '',
            errorMessages: [],
        }
    },
    methods: {
        async submitNewUser() {
            this.errorMessages = []
            if (this.name === '' || this.surname === '') {
                this.errorMessages.push('Vul alle gegevens van de gebruiker in.')
                return
            }

            const newPass = this.generatePassword()
            const newDate = new Date
            await secondaryFb.auth().createUserWithEmailAndPassword(this.email, newPass).then(async (authUser) => {

                const newUid = authUser.user.uid
                var newUser = {
                    renewDate: newDate,
                    endDate: addDays(newDate, 365),
                    userData: {
                        displayName: this.name + this.surname,
                        name: this.name,
                        surname: this.surname,
                        email: this.email,
                        savedVideos: [],
                        photoURL: '',
                        lastSeen: newDate
                    }
                }

                const userRef = projectDb.collection('users').doc(newUid)
                const expRef = projectDb.collection('exp_dates').doc(newUid)

                secondaryFb.auth().sendPasswordResetEmail(this.email)
                secondaryFb.auth().signOut();

                userRef.set(newUser);
                await expRef.set({
                    user: this.email,
                    uid: newUid,
                    createDate: newDate,
                    endDate: addDays(newDate, 365),
                })

                this.$emit('closeAndReload')
            }).catch(error => {
                switch (error.code) {
                    case 'auth/email-already-in-use':
                        this.errorMessages.push('Dit e-mailadres is al in gebruik.')
                        break;
                    case 'auth/invalid-email':
                        this.errorMessages.push('Ongeldig e-mailadres.')
                        break;
                    case 'auth/operation-not-allowed':
                        this.errorMessages.push('Handeling niet toegestaan.')
                        break;
                    case 'auth/weak-password':
                        this.errorMessages.push('Fout met wachtwoord genereren.')
                        break;
                    case 'auth/missing-email':
                        this.errorMessages.push('Geen e-mailadres ingevoerd.')
                        break;
                    default:
                        this.errorMessages.push(error.message)
                        break;
                }
            })
        },
        generatePassword() {
            var length = 10,
                charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
                retVal = "";
            for (var i = 0, n = charset.length; i < length; ++i) {
                retVal += charset.charAt(Math.floor(Math.random() * n));
            }
            return retVal;
        }
    }
}
</script>