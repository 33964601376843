<template>
    <div class="flex h-dvh w-full">
        <the-navbar></the-navbar>
        <div class="pt-8 lg:p-12 overflow-auto flex w-full justify-center ">
            <div class="flex flex-col gap-6 h-full w-full max-w-screen-xl container xl:px-32">
                <h1 class="text-lg lg:text-2xl px-4 lg:px-0 font-semibold flex md:block items-center justify-center">
                    Gebruikersbeheer</h1>
                <!-- Stat cards -->
                <div class="py-6 border-y lg:border border-neutral-200 gap-6 bg-neutral-50 lg:rounded-xl" v-if="false">
                    <Splide class="w-full" :options="options">
                        <SplideSlide v-for="stat in stats" :key="stat.name">
                            <div class="bg-white border border-neutral-200 p-4 rounded-lg relative">
                                <p class="text-xs text-neutral-400 mb-3">{{ stat.name }}</p>
                                <h2 class="text-3xl font-bold">{{ stat.value }}</h2>
                                <div class="absolute px-2 py-1 rounded bottom-4  right-4 text-[10px]"
                                    :class="stat.change > 0 ? 'bg-green-600/10' : 'bg-red-600/10'">{{ stat.change + "%"
                                    }}
                                </div>
                            </div>
                        </SplideSlide>
                    </Splide>
                </div>
                <div class="flex justify-between items-center px-4 lg:px-0">
                    <h2 class="font-semibold text-sm sm:text-base">Alle gebruikers:</h2>
                    <div @click="newUserModalOpen = true"
                        class="flex gap-2 bg-green-600 px-5 cursor-pointer rounded-full py-2.5 shadow-md text-white transition-colors hover:bg-green-600/75">
                        <p class="text-xs font-semibold">Nieuwe gebruiker</p>
                        <fa-icon icon="plus" />
                    </div>
                </div>
                <!-- User list -->
                <div class="flex flex-col overflow-hidden h-auto px-4 lg:px-0">
                    <div
                        class="header bg-neutral-50 rounded-t-lg border border-neutral-200 border-b-0 px-4 py-3 text-neutral-500 grid grid-cols-2 lg:grid-cols-6 text-sm">
                        <p class="lg:col-span-2">Gebruiker</p>

                        <p class="hidden lg:block">Laatst gezien</p>
                        <p class="hidden lg:block">Looptijd</p>
                        <p class="block text-right sm:text-left lg:col-span-2">Acties</p>
                    </div>
                    <div class="border border-neutral-200 border-t-0 h-auto rounded-b-lg overflow-y-auto">
                        <div v-for="user in getList" :key="user"
                            class="even:bg-neutral-50 px-4 py-2.5 grid grid-cols-2 lg:grid-cols-6 items-center">
                            <!-- Img, name and email -->
                            <div class="flex items-center gap-3 lg:col-span-2">
                                <div
                                    class="rounded-full bg-green-600/10 aspect-square h-10 overflow-hidden shrink-0 flex items-center justify-center">
                                    <img v-if="user.userData" :src="getUserAvatar(user.userData)" />
                                </div>
                                <div>
                                    <p class="text-sm">{{ user.userData.name + ' ' + user.userData.surname }}</p>
                                    <p class="text-[11px] text-neutral-500">{{ user.userData.email }}</p>
                                </div>
                            </div>
                            <!-- Last seen -->
                            <p class="text-xs hidden lg:block">
                                {{ getUserLastSeen(user.userData.lastSeen) }}
                            </p>
                            <!-- Valid through -->
                            <p class="text-xs hidden lg:block"><span class="font-semibold">Geldig tot:
                                </span>
                                <span v-if="user.endDate">{{ getExpDate(user.endDate) }}</span>
                            </p>
                            <div class="flex items-end justify-end sm:justify-normal gap-4  lg:col-span-2">
                                <div @click="userPassToReset = user.userData"
                                    class="text-xs flex gap-2 items-center bg-red-500/10 cursor-pointer text-red-600 p-3 sm:py-1 sm:px-3 rounded-md">
                                    <fa-icon icon="key" class="cursor-pointer" />
                                    <p class="hidden sm:block">wachtwoord reset</p>
                                </div>
                                <button @click="userToRenew = user.userData" :disabled="renewedToday(user.renewDate)"
                                    class="text-xs flex gap-2 items-center bg-green-600 disabled:opacity-40 cursor-pointer text-white p-3 sm:py-1 sm:px-3 rounded-md">
                                    <fa-icon icon="arrows-rotate" class="cursor-pointer" />
                                    <p class="hidden sm:block">Vernieuwen</p>
                                </button>
                            </div>
                        </div>
                        <div v-if="getList.length === 0" class="even:bg-neutral-50 px-4 py-2.5 text-xs">
                            <p>Geen gebruikers gevonden</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <transition name="fade">
            <the-new-user-modal v-if="newUserModalOpen" @close="newUserModalOpen = false"
                @closeAndReload="closeAndReload()"></the-new-user-modal>
        </transition>
        <transition name="fade">
            <the-remove-user-modal v-if="userToRemove" @close="userToRemove = false" :user="userToRemove"
                @closeAndReload="closeAndReload()"></the-remove-user-modal>
        </transition>
        <transition name="fade">
            <the-renew-user-modal v-if="userToRenew" @close="userToRenew = false" :user="userToRenew"
                @closeAndReload="closeAndReload()"></the-renew-user-modal>
        </transition>
        <transition name="fade">
            <the-reset-user-pass-modal v-if="userPassToReset" @close="userPassToReset = false" :user="userPassToReset"
                @closeAndReload="closeAndReload()"></the-reset-user-pass-modal>
        </transition>
    </div>
</template>
<script>
import TheNavbar from '@/components/TheNavbar.vue';
import TheNewUserModal from "@/components/user-management/TheNewUserModal.vue"
import TheRemoveUserModal from "@/components/user-management/TheRemoveUserModal.vue"
import TheRenewUserModal from "@/components/user-management/TheRenewUserModal.vue"
import TheResetUserPassModal from "@/components/user-management/TheResetUserPassModal.vue"
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';
import { fromUnixTime, format, isToday } from 'date-fns';
import { createAvatar } from '@dicebear/core';
import { initials } from '@dicebear/collection';

export default {
    components: { TheNavbar, TheNewUserModal, Splide, SplideSlide, TheRemoveUserModal, TheRenewUserModal, TheResetUserPassModal },
    data() {
        return {
            newUserModalOpen: false,
            userToRemove: '',
            userToRenew: '',
            userPassToReset: '',
            users: [],
            options: {
                rewind: true,
                perPage: 5,
                gap: '16px',
                snap: false,
                arrows: false,
                pagination: false,
                breakpoints: {
                    1280: {
                        perPage: 4,
                        gap: "12px"
                    },
                    1024: {
                        perPage: 3
                    },
                    640: {
                        perPage: 2,
                        padding: {
                            left: 24,
                            right: 80
                        }
                    },
                    500: {
                        perPage: 1,
                        padding: {
                            left: 24,
                            right: 80
                        }
                    }
                }
            }
        }
    },
    computed: {
        getList() {
            const list = this.$store.getters.getUsersList
            return list.sort((a, b) => a.endDate.seconds - b.endDate.seconds);
        }
    },
    methods: {
        getExpDate(timestamp) {
            if (timestamp) {
                return format(fromUnixTime(timestamp.seconds), 'dd-MM-yyyy');
            } else { return '-' }
        },
        getUserLastSeen(timestamp) {
            if (timestamp) {
                return format(fromUnixTime(timestamp.seconds), 'dd-MM-yyyy');
            } else { return '-' }
        },
        closeAndReload() {
            this.newUserModalOpen = false
            this.$router.go()
        },
        renewedToday(timestamp) {

            if (timestamp) {
                let renewDate = fromUnixTime(timestamp.seconds)
                if (isToday(renewDate)) { return true }
            } else { return false }
        },
        getUserAvatar(userData) {
            const firstLetters = userData.name.slice(0, 1) + userData.surname.slice(0, 1)
            return createAvatar(initials, {
                size: 128,
                seed: firstLetters,
            }).toDataUriSync()
        }
    },
    async mounted() {
        this.$store.dispatch('loadExpDates')
        document.title = "T'ai Chi voor thuis - Gebruikers";
    },

}
</script>
asdf