import Vuex from "vuex";
import { projectAuth, projectDb } from "../../firebaseConfig.js";
import { getUnixTime } from "date-fns";

export default new Vuex.Store({
  state: {
    user: null,
    userData: null,
    savedVideos: [],
    endDate: null,
    subValid: null,
    videoData: null,
    videoIndex: [],
    userIsAdmin: false,
    usersList: [],
    expDates: [],
  },
  mutations: {
    loadUser(state) {
      state.user = null;
      state.userData = null;
      projectAuth.onAuthStateChanged((user) => {
        if (user) {
          projectDb.collection("users").doc(user.uid).update({
            "userData.lastSeen": new Date(),
          });

          state.user = user;
          const uid = user.uid;
          projectDb
            .collection("users")
            .doc(uid)
            .onSnapshot((doc) => {
              let data = doc.data();
              state.userData = data.userData;
              state.endDate = data.endDate.seconds;
            });
        }
      });
    },
    loadVideoIndex(state) {
      state.videoData = [];

      projectAuth.onAuthStateChanged((user) => {
        if (user) {
          const expRef = projectDb.collection("exp_dates").doc(user.uid);
          const videoRef = projectDb.collection("videos");

          expRef.get().then((doc) => {
            if (doc.exists) {
              const data = doc.data();
              const endDate = data.endDate.seconds;
              const currentDate = getUnixTime(new Date());
              state.subValid = endDate > currentDate;

              if (endDate < currentDate) {
                return;
              }

              videoRef.get().then((querySnapshot) => {
                state.videoIndex = [];
                let index = 0;

                if (querySnapshot.empty) {
                  return;
                }

                querySnapshot.forEach((doc) => {
                  const category = doc.data();
                  category.id = doc.id;
                  category.index = index;
                  category.videos = [];
                  let categoryRef = projectDb.collection(
                    "/videos/" + category.id + "/videos"
                  );

                  categoryRef.get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                      category.videos.push(doc.data());
                    });

                    state.videoIndex.push(category);
                  });

                  index++;
                });
              });
            }
          });
        }
      });
    },
    loadSavedVideos(state) {
      projectAuth.onAuthStateChanged((user) => {
        if (user) {
          projectDb
            .collection("user/" + user.uid + "/savedVideos")
            .onSnapshot((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                state.savedVideos.push(doc.data());
              });
            });
        }
      });
    },
    loadUsersList(state) {
      state.usersList = [];
      projectAuth.onAuthStateChanged((user) => {
        if (user) {
          let adminsRef = projectDb.collection("admins");
          let docRef = adminsRef.doc(user.uid);

          docRef.get().then((doc) => {
            if (doc.exists) {
              state.userIsAdmin = true;
              let usersRef = projectDb.collection("users");
              usersRef.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  var user = doc.data();
                  user.userData.uid = doc.id;
                  state.usersList.push(user);
                });
              });
            } else {
              state.userIsAdmin = false;
            }
          });
        }
      });
    },
    async loadExpDates(state) {
      const expDates = await projectDb.collection("exp_dates");
      expDates.get().then((querySnapshot) => {
        const tempDoc = [];
        querySnapshot.forEach((doc) => {
          tempDoc.push({ uid: doc.id, ...doc.data() });
        });
        state.expDates = tempDoc;
      });
    },
  },
  actions: {
    loadUser(context) {
      context.commit("loadUser");
    },
    loadVideoIndex(context) {
      context.commit("loadVideoIndex");
    },
    loadSavedVideos(context) {
      context.commit("loadSavedVideos");
    },
    loadUsersList(context) {
      context.commit("loadUsersList");
    },
    loadExpDates(context) {
      context.commit("loadExpDates");
    },
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getUserData(state) {
      return state.userData;
    },
    getSavedVideos(state) {
      return state.savedVideos;
    },
    getEndDate(state) {
      return state.endDate;
    },
    getVideoIndex(state) {
      return state.videoIndex;
    },
    getUsersList(state) {
      return state.usersList;
    },
    getUserIsAdmin(state) {
      return state.userIsAdmin;
    },
    getSubValid(state) {
      return state.subValid;
    },
    getExpDates(state) {
      return state.expDates;
    },
  },
});
