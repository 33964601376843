import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store/store";
import "./styles/app.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faPlay,
  faComment,
  faUser,
  faCog,
  faTimes,
  faBars,
  faSignOutAlt,
  faChevronDown,
  faMagnifyingGlass,
  faCircleNotch,
  faRotateLeft,
  faDumbbell,
  fa2,
  faHeart,
  faChevronRight,
  faArrowLeft,
  faUsers,
  faPlus,
  faTriangleExclamation,
  faArrowRight,
  faCircleCheck,
  faEye,
  faEyeSlash,
  faTrashCan,
  faArrowsRotate,
  faKey,
  faMinus,
  faTabletScreenButton,
  faObjectGroup,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faPlay,
  faComment,
  faUser,
  faCog,
  faTimes,
  faBars,
  faSignOutAlt,
  faChevronDown,
  faMagnifyingGlass,
  faCircleNotch,
  faRotateLeft,
  faDumbbell,
  fa2,
  faHeart,
  faChevronRight,
  faArrowLeft,
  faUsers,
  faPlus,
  faTriangleExclamation,
  faArrowRight,
  faCircleCheck,
  faEye,
  faEyeSlash,
  faTrashCan,
  faArrowsRotate,
  faKey,
  faMinus,
  faTabletScreenButton,
  faObjectGroup
);

const app = createApp(App);

app.component("fa-icon", FontAwesomeIcon);

app.use(store);
app.use(router);
app.mount("#app");
