<template>
    <div class="w-screen  h-screen fixed flex items-center justify-center z-30">
        <div class="bg-black/70 absolute z-30 w-full h-full cursor-pointer" @click="$emit('close')"></div>
        <div class="p-6 rounded-2xl text-center bg-white z-30 max-w-xs flex flex-col items-center gap-4 relative">
            <fa-icon class="text-red-500 h-8" icon="triangle-exclamation" />
            <h2 class="py-1 rounded-md text-lg font-semibold text-red-500">Wachtwoord resetten?
            </h2>
            <p class="text-sm ">U staat op het punt uw wachtwoord te resetten, d.m.v. een link die naar
                uw emailadres
                zal worden gestuurd, kan
                u uw wachtwoord veranderen. Weet u zeker dat u dit wilt doen? </p>
            <div class=" w-full flex flex-col gap-3 mt-4">
                <button @click="submitReset"
                    class="grow p-3 bg-red-500 hover:opacity-80 rounded-md transition-opacity text-white font-semibold text-sm">
                    Resetten</button>
                <button @click="$emit('close')"
                    class="grow p-3 bg-green-600/10 rounded-md hover:opacity-80 transition-opacity text-green-600 font-semibold text-sm">Annuleren</button>
            </div>
        </div>
    </div>
</template>
<script>
import { projectAuth } from '../../firebaseConfig.js';

export default {
    emits: ['close'],
    methods: {
        submitReset() {
            projectAuth.onAuthStateChanged((user) => {
                projectAuth.sendPasswordResetEmail(user.email).then(this.$emit('close'))
            });
        }
    },
}
</script>
