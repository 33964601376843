<template>
  <transition name="fade">
    <the-welcome-modal v-if="getShowModal && $route.fullPath === '/home'"
      @close="openModal = false"></the-welcome-modal>
  </transition>
  <router-view />
  <the-dev-tools v-if="false"></the-dev-tools>
</template>
<script>
import TheDevTools from "./components/TheDevTools.vue";
import TheWelcomeModal from "@/components/TheWelcomeModal.vue"

export default {
  data() {
    return {
      openModal: false
    }
  },
  components: {
    TheDevTools,
    TheWelcomeModal
  },
  computed: {
    getShowModal() {
      let userData = this.$store.getters.getUserData
      if (userData) {
        if (userData.dismissedWelcome !== true) {
          return true
        }
      }
      return false
    }
  },
  beforeMount() {
    this.$store.dispatch('loadUser');
    this.$store.dispatch('loadVideoIndex');
    this.$store.dispatch('loadUsersList');
  },

}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
</style>