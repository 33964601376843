<template>
    <div class="fixed bottom-0 right-0 p-4 bg-green-600/10">
        <p class="text-xs">devTools</p>
        <button class="bg-red-500 text-white text-xs px-4 p-2 rounded" @click="uploadList">Write local videoIndex to db
            (!)</button>
    </div>
</template>
<script>
import { projectDb } from "../../firebaseConfig";


export default {
    methods: {

        // TODO: Remove
        // DEV TOOL: overwrites the firebase videos database with the version from VueX, BE CAREFULL, OVERWRITES ALL db/videos/* DATA
        uploadList() {
            let list = [
                /* warming up */
                [
                    {
                        id: "0",
                        category: '0_warming_up',
                        name: "Warming up",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/01_korte_vorm/thumbnail_0.png",
                        src: "/assets/video/0_warming_up/warming_up_1_compressed.mp4",
                    },
                ],
                [
                    /* complete vorm */
                    {
                        id: "0",
                        category: '1_complete_vorm',
                        name: "Complete Korte Vorm",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/01_korte_vorm/thumbnail_0.png",
                        src: "/assets/video/1_hele_vorm/01_hele_vorm_voor.mp4",
                    },
                    {
                        id: "1",
                        category: '1_complete_vorm',
                        name: "Complete Korte Vorm",
                        subtitle: "Gefilmd vanaf de achterkant",
                        thumbnail: "/assets/thumbnails/01_korte_vorm/thumbnail_1.png",
                        src: "/assets/video/1_hele_vorm/02_hele_vorm_achter_corrected.mp4",
                        reverse: true,
                    },
                    {
                        id: "2",
                        category: '1_complete_vorm',
                        name: "Plaatsen van voeten ",
                        subtitle: "i.r.l. de Korte Vorm",
                        thumbnail: "/assets/thumbnails/01_korte_vorm/thumbnail_2.png",
                        src: "/assets/video/1_hele_vorm/03_plaatsen_voeten_irt_vorm.mp4",
                    },
                ],
                [
                    /* vorm in delen */
                    {
                        id: "0",
                        category: "2_vorm_in_delen",
                        name: "Eerste twee houdingen ",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/02_vorm_delen/thumbnail_00.png",
                        src: "/assets/video/2_vorm_delen/01_eerste_twee_houdingen.mp4",
                    },
                    {
                        id: "1",
                        category: "2_vorm_in_delen",
                        name: "Eerste twee houdingen ",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/02_vorm_delen/thumbnail_01.png",
                        src: "/assets/video/2_vorm_delen/02_eerste_twee_houdingen_2x.mp4",
                    },
                    {
                        id: "2",
                        category: "2_vorm_in_delen",
                        name: "Eerste twee houdingen ",
                        subtitle: "Gefilmd vanaf de achterkant",
                        thumbnail: "/assets/thumbnails/02_vorm_delen/thumbnail_02.png",
                        src: "/assets/video/2_vorm_delen/03_eerste_twee_houdingen_achter.mp4",
                        reverse: true,
                    },
                    {
                        id: "3",
                        category: "2_vorm_in_delen",
                        name: "Eerste twee houdingen 2X ",
                        subtitle: "Gefilmd vanaf de achterkant",
                        thumbnail: "/assets/thumbnails/02_vorm_delen/thumbnail_03.png",
                        src: "/assets/video/2_vorm_delen/04_eerste_twee_houdingen_achter_2x.mp4",
                        reverse: true,
                        twoTimes: true,
                    },
                    {
                        id: "4",
                        category: "2_vorm_in_delen",
                        name: "Eerste drie houdingen ",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/02_vorm_delen/thumbnail_04.png",
                        src: "/assets/video/2_vorm_delen/05_eerste_drie_houdingen.mp4",
                    },
                    {
                        id: "5",
                        category: "2_vorm_in_delen",
                        name: "Eerste drie houdingen 2X ",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/02_vorm_delen/thumbnail_05.png",
                        src: "/assets/video/2_vorm_delen/06_eerste_drie_houdingen_2x.mp4",
                        twoTimes: true,
                    },
                    {
                        id: "6",
                        category: "2_vorm_in_delen",
                        name: "Eerste drie houdingen ",
                        subtitle: "Gefilmd vanaf de achterkant",
                        thumbnail: "/assets/thumbnails/02_vorm_delen/thumbnail_06.png",
                        src: "/assets/video/2_vorm_delen/07_eerste_drie_houdingen_achter.mp4",
                        reverse: true,
                    },
                    {
                        id: "7",
                        category: "2_vorm_in_delen",
                        name: "Eerste drie houdingen 2X",
                        subtitle: "Gefilmd vanaf de achterkant",
                        thumbnail: "/assets/thumbnails/02_vorm_delen/thumbnail_07.png",
                        src: "/assets/video/2_vorm_delen/08_eerste_drie_houdingen_achter_2x.mp4",
                        reverse: true,
                        twoTimes: true,
                    },
                    {
                        id: "8",
                        category: "2_vorm_in_delen",
                        name: "Vierde t/m zesde houding",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/02_vorm_delen/thumbnail_08.png",
                        src: "/assets/video/2_vorm_delen/09_vierde_tm_zesde_houding.mp4",
                    },
                    {
                        id: "9",
                        category: "2_vorm_in_delen",
                        name: "Vierde t/m zesde houding",
                        subtitle: "Gefilmd vanaf de achterkant",
                        thumbnail: "/assets/thumbnails/02_vorm_delen/thumbnail_09.png",
                        src: "/assets/video/2_vorm_delen/10_vierde_tm_zesde_houding_achter.mp4",
                        reverse: true,
                    },
                    {
                        id: "10",
                        category: "2_vorm_in_delen",
                        name: "Eerste zes houdingen",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/02_vorm_delen/thumbnail_10.png",
                        src: "/assets/video/2_vorm_delen/11_eerste_zes_houdingen.mp4",
                    },
                    {
                        id: "11",
                        category: "2_vorm_in_delen",
                        name: "Eerste zes houdingen",
                        subtitle: "Gefilmd vanaf de achterkant",
                        thumbnail: "/assets/thumbnails/02_vorm_delen/thumbnail_11.png",
                        src: "/assets/video/2_vorm_delen/12_eerste_zes_houdingen_achter.mp4",
                        reverse: true,
                    },
                    {
                        id: "12",
                        category: "2_vorm_in_delen",
                        name: "houding 7 t/m 18",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/02_vorm_delen/thumbnail_12.png",
                        src: "/assets/video/2_vorm_delen/13_houding_7_tm_18.mp4",
                    },
                    {
                        id: "13",
                        category: "2_vorm_in_delen",
                        name: "houding 7 t/m 18",
                        subtitle: "Gefilmd vanaf de achterkant",
                        thumbnail: "/assets/thumbnails/02_vorm_delen/thumbnail_13.png",
                        src: "/assets/video/2_vorm_delen/14_houding_7_tm_18_achter.mp4",
                        reverse: true,
                    },
                    {
                        id: "14",
                        category: "2_vorm_in_delen",
                        name: "houding 19 t/m 24 (afsluiting)",
                        subtitle: "Gefilmd vanaf de Voorkant",
                        thumbnail: "/assets/thumbnails/02_vorm_delen/thumbnail_14.png",
                        src: "/assets/video/2_vorm_delen/15_houding_19_tm_24_afsluiting.mp4",
                    },
                    {
                        id: "13",
                        category: "2_vorm_in_delen",
                        name: "houding 19 t/m 24 (afsluiting)",
                        subtitle: "Gefilmd vanaf de achterkant",
                        thumbnail: "/assets/thumbnails/02_vorm_delen/thumbnail_15.png",
                        src: "/assets/video/2_vorm_delen/16_houding_19_tm_24_afsluiting_achter.mp4",
                        reverse: true,
                    },
                ],
                /* vorm in houdingen */
                [
                    {
                        id: "0",
                        category: "3_vorm_in_houdingen",
                        name: "1. Openen van de bron",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00001.png",
                        src: "/assets/video/3_vorm_houdingen/01_openen_van_de_bron.mp4",
                    },
                    {
                        id: "1",
                        category: "3_vorm_in_houdingen",
                        name: "1. Openen van de bron (oefening)",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00002.png",
                        src: "/assets/video/3_vorm_houdingen/02_openen_bron_met_oefening.mp4",
                        exercise: true,
                    },
                    {
                        id: "2",
                        category: "3_vorm_in_houdingen",
                        name: "1. Openen van de bron (oefening)",
                        subtitle: "Gefilmd vanaf de achterkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00003.png",
                        src: "/assets/video/3_vorm_houdingen/03_openen_bron_met_oefening_achter.mp4",
                        reverse: true,
                        exercise: true,
                    },
                    {
                        id: "3",
                        category: "3_vorm_in_houdingen",
                        name: "2. Grote bal",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00004.png",
                        src: "/assets/video/3_vorm_houdingen/04_grote_bal.mp4",
                    },
                    {
                        id: "4",
                        category: "3_vorm_in_houdingen",
                        name: "2. Grote bal (oefening)",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00005.png",
                        src: "/assets/video/3_vorm_houdingen/05_grote_bal_oefening.mp4",
                        exercise: true,
                    },
                    {
                        id: "5",
                        category: "3_vorm_in_houdingen",
                        name: "2. Grote bal (oefening)",
                        subtitle: "Gefilmd vanaf de achterkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00006.png",
                        src: "/assets/video/3_vorm_houdingen/06_grote_bal_oefening_achter.mp4",
                        reverse: true,
                        exercise: true,
                    },
                    {
                        id: "6",
                        category: "3_vorm_in_houdingen",
                        name: "3. Pak de vogel bij de staart",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00007.png",
                        src: "/assets/video/3_vorm_houdingen/07_pak_volgel_bij_staart.mp4",
                    },
                    {
                        id: "7",
                        category: "3_vorm_in_houdingen",
                        name: "3. Pak de vogel bij de staart (oefening)",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00008.png",
                        src: "/assets/video/3_vorm_houdingen/08_pak_volgel_bij_staart_oefening.mp4",
                        exercise: true,
                    },
                    {
                        id: "8",
                        category: "3_vorm_in_houdingen",
                        name: "3. Pak de vogel bij de staart (oefening)",
                        subtitle: "Gefilmd vanaf de achterkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00009.png",
                        src: "/assets/video/3_vorm_houdingen/09_pak_volgel_bij_staart_oefening_achter.mp4",
                        reverse: true,
                        exercise: true,
                    },
                    {
                        id: "9",
                        category: "3_vorm_in_houdingen",
                        name: "4. Berghouding afstrijken",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00010.png",
                        src: "/assets/video/3_vorm_houdingen/10_berghouding_afstrijken.mp4",
                    },
                    {
                        id: "10",
                        category: "3_vorm_in_houdingen",
                        name: "4. Berghouding afstrijken (oefening)",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00011.png",
                        src: "/assets/video/3_vorm_houdingen/11_berghouding_afstrijken_oefening.mp4",
                        exercise: true,
                    },
                    {
                        id: "11",
                        category: "3_vorm_in_houdingen",
                        name: "4. Berghouding afstrijken (oefening)",
                        subtitle: "Gefilmd vanaf de achterkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00012.png",
                        src: "/assets/video/3_vorm_houdingen/12_berghouding_afstrijken_oefening_achter.mp4",
                        reverse: true,
                        exercise: true,
                    },
                    {
                        id: "12",
                        category: "3_vorm_in_houdingen",
                        name: "5. Kleine bal veeg oor",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00013.png",
                        src: "/assets/video/3_vorm_houdingen/13_kleine_bal_veeg_oor.mp4",
                    },
                    {
                        id: "13",
                        category: "3_vorm_in_houdingen",
                        name: "5. Kleine bal veeg oor (oefening)",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00014.png",
                        src: "/assets/video/3_vorm_houdingen/14_kleine_bal_veeg_oor_oefening.mp4",
                        exercise: true,
                    },
                    {
                        id: "14",
                        category: "3_vorm_in_houdingen",
                        name: "5. Kleine bal veeg oor (oefening)",
                        subtitle: "Gefilmd vanaf de achterkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00015.png",
                        src: "/assets/video/3_vorm_houdingen/15_kleine_bal_veeg_oor_oefening_achter.mp4",
                        reverse: true,
                        exercise: true,
                    },
                    {
                        id: "15",
                        category: "3_vorm_in_houdingen",
                        name: "6. Veeg langs het oor met duw",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00016.png",
                        src: "/assets/video/3_vorm_houdingen/16_veeg_langs_oor_duw.mp4",
                    },
                    {
                        id: "16",
                        category: "3_vorm_in_houdingen",
                        name: "6. Veeg langs het oor met duw (oefening)",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00017.png",
                        src: "/assets/video/3_vorm_houdingen/17_veeg_langs_oor_duw_oefening.mp4",
                        exercise: true,
                    },
                    {
                        id: "17",
                        category: "3_vorm_in_houdingen",
                        name: "6. Veeg langs het oor met duw (oefening)",
                        subtitle: "Gefilmd vanaf de achterkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00018.png",
                        src: "/assets/video/3_vorm_houdingen/18_veeg_langs_oor_duw_oefening_achter.mp4",
                        reverse: true,
                        exercise: true,
                    },
                    {
                        id: "18",
                        category: "3_vorm_in_houdingen",
                        name: "7. Duw met enkelvoudige zweepslag",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00019.png",
                        src: "/assets/video/3_vorm_houdingen/19_duw_enkelvoudige_zweepslag.mp4",
                    },
                    {
                        id: "19",
                        category: "3_vorm_in_houdingen",
                        name: "7. Duw met enkelvoudige zweepslag (oefening)",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00020.png",
                        src: "/assets/video/3_vorm_houdingen/20_duw_enkelvoudige_zweepslag_oefening.mp4",
                        exercise: true,
                    },
                    {
                        id: "20",
                        category: "3_vorm_in_houdingen",
                        name: "7. Duw met enkelvoudige zweepslag (oefening)",
                        subtitle: "Gefilmd vanaf de achterkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00021.png",
                        src: "/assets/video/3_vorm_houdingen/21_duw_enkelvoudige_zweepslag_oefening_achter.mp4",
                        reverse: true,
                        exercise: true,
                    },
                    {
                        id: "21",
                        category: "3_vorm_in_houdingen",
                        name: "8. Openen van de borstkas",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00022.png",
                        src: "/assets/video/3_vorm_houdingen/22_openen_borstkas.mp4",
                    },
                    {
                        id: "22",
                        category: "3_vorm_in_houdingen",
                        name: "8. Openen van de borstkas (oefening)",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00023.png",
                        src: "/assets/video/3_vorm_houdingen/23_openen_borstkas_oefening.mp4",
                        exercise: true,
                    },
                    {
                        id: "23",
                        category: "3_vorm_in_houdingen",
                        name: "8. Openen van de borstkas (oefening)",
                        subtitle: "Gefilmd vanaf de achterkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00024.png",
                        src: "/assets/video/3_vorm_houdingen/24_openen_borstkas_oefening_achter.mp4",
                        reverse: true,
                        exercise: true,
                    },
                    {
                        id: "24",
                        category: "3_vorm_in_houdingen",
                        name: "9. Duw met de rechter schouder",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00025.png",
                        src: "/assets/video/3_vorm_houdingen/25_duw_met_rechter_schouder.mp4",
                    },
                    {
                        id: "25",
                        category: "3_vorm_in_houdingen",
                        name: "9. Duw met de rechter schouder (oefening)",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00026.png",
                        src: "/assets/video/3_vorm_houdingen/26_duw_met_rechter_schouder_oefening.mp4",
                        exercise: true,
                    },
                    {
                        id: "26",
                        category: "3_vorm_in_houdingen",
                        name: "9. Duw met de rechter schouder (oefening)",
                        subtitle: "Gefilmd vanaf de achterkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00027.png",
                        src: "/assets/video/3_vorm_houdingen/27_duw_met_rechter_schouder_oefening_achter.mp4",
                        reverse: true,
                        exercise: true,
                    },
                    {
                        id: "27",
                        category: "3_vorm_in_houdingen",
                        name: "10. Kraanvogel vleugels",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00028.png",
                        src: "/assets/video/3_vorm_houdingen/28_kraanvogel_vleugels.mp4",
                    },
                    {
                        id: "28",
                        category: "3_vorm_in_houdingen",
                        name: "10. Kraanvogel vleugels (oefening)",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00029.png",
                        src: "/assets/video/3_vorm_houdingen/29_kraanvogel_vleugels_oefening.mp4",
                        exercise: true,
                    },
                    {
                        id: "29",
                        category: "3_vorm_in_houdingen",
                        name: "10. Kraanvogel vleugels (oefening)",
                        subtitle: "Gefilmd vanaf de achterkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00030.png",
                        src: "/assets/video/3_vorm_houdingen/30_kraanvogel_vleugels_oefening_achter.mp4",
                        reverse: true,
                        exercise: true,
                    },
                    {
                        id: "30",
                        category: "3_vorm_in_houdingen",
                        name: "11. Eerste veeg langs ook en knie",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00031.png",
                        src: "/assets/video/3_vorm_houdingen/31_eerste_veeg_langs_oor_plus_eerste_veeg_langs_knie.mp4",
                    },
                    {
                        id: "31",
                        category: "3_vorm_in_houdingen",
                        name: "11. Eerste veeg langs ook en knie (oefening)",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00032.png",
                        src: "/assets/video/3_vorm_houdingen/32_eerste_veeg_langs_oor_plus_eerste_veeg_langs_knie_oefening.mp4",
                        exercise: true,
                    },
                    {
                        id: "32",
                        category: "3_vorm_in_houdingen",
                        name: "11. Eerste veeg langs ook en knie (oefening)",
                        subtitle: "Gefilmd vanaf de achterkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00033.png",
                        src: "/assets/video/3_vorm_houdingen/33_eerste_veeg_langs_oor_plus_eerste_veeg_langs_knie_oefening_achter.mp4",
                        reverse: true,
                        exercise: true,
                    },
                    {
                        id: "33",
                        category: "3_vorm_in_houdingen",
                        name: "12. Bespeel de gitaar",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00034.png",
                        src: "/assets/video/3_vorm_houdingen/34_bespeeld_de_gitaar.mp4",
                    },
                    {
                        id: "34",
                        category: "3_vorm_in_houdingen",
                        name: "12. Bespeel de gitaar (oefening)",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00035.png",
                        src: "/assets/video/3_vorm_houdingen/35_bespeeld_de_gitaar_oefening.mp4",
                        exercise: true,
                    },
                    {
                        id: "35",
                        category: "3_vorm_in_houdingen",
                        name: "12. Bespeel de gitaar (oefening)",
                        subtitle: "Gefilmd vanaf de achterkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00036.png",
                        src: "/assets/video/3_vorm_houdingen/36_bespeeld_de_gitaar_oefening_achter.mp4",
                        reverse: true,
                        exercise: true,
                    },
                    {
                        id: "36",
                        category: "3_vorm_in_houdingen",
                        name: "13. Tweede veeg oor en knie",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00037.png",
                        src: "/assets/video/3_vorm_houdingen/37_tweede_veeg_oor_knie.mp4",
                    },
                    {
                        id: "37",
                        category: "3_vorm_in_houdingen",
                        name: "13. Tweede veeg oor en knie (oefening)",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00038.png",
                        src: "/assets/video/3_vorm_houdingen/38_tweede_veeg_oor_knie_oefening.mp4",
                        exercise: true,
                    },
                    {
                        id: "38",
                        category: "3_vorm_in_houdingen",
                        name: "13. Tweede veeg oor en knie (oefening)",
                        subtitle: "Gefilmd vanaf de achterkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00039.png",
                        src: "/assets/video/3_vorm_houdingen/39_tweede_veeg_oor_knie_oefening_achter.mp4",
                        reverse: true,
                        exercise: true,
                    },
                    {
                        id: "39",
                        category: "3_vorm_in_houdingen",
                        name: "14. Aansluiting Wu stap",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00040.png",
                        src: "/assets/video/3_vorm_houdingen/40_aansluiting_wustap.mp4",
                    },
                    {
                        id: "40",
                        category: "3_vorm_in_houdingen",
                        name: "14. Aansluiting Wu stap (oefening)",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00041.png",
                        src: "/assets/video/3_vorm_houdingen/41_aansluiting_wustap_oefening.mp4",
                        exercise: true,
                    },
                    {
                        id: "41",
                        category: "3_vorm_in_houdingen",
                        name: "14. Aansluiting Wu stap (oefening)",
                        subtitle: "Gefilmd vanaf de achterkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00042.png",
                        src: "/assets/video/3_vorm_houdingen/42_aansluiting_wustap_oefening_achter.mp4",
                        reverse: true,
                        exercise: true,
                    },
                    {
                        id: "42",
                        category: "3_vorm_in_houdingen",
                        name: "15. Wu stap",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00043.png",
                        src: "/assets/video/3_vorm_houdingen/43_wustap.mp4",
                    },
                    {
                        id: "43",
                        category: "3_vorm_in_houdingen",
                        name: "15. Wu stap (oefening)",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00044.png",
                        src: "/assets/video/3_vorm_houdingen/44_wustap_oefening.mp4",
                        exercise: true,
                    },
                    {
                        id: "44",
                        category: "3_vorm_in_houdingen",
                        name: "15. Wu stap (oefening)",
                        subtitle: "Gefilmd vanaf de achterkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00045.png",
                        src: "/assets/video/3_vorm_houdingen/45_wustap_oefening_achter.mp4",
                        reverse: true,
                        exercise: true,
                    },
                    {
                        id: "45",
                        category: "3_vorm_in_houdingen",
                        name: "16. De stomp",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00046.png",
                        src: "/assets/video/3_vorm_houdingen/46_de_stomp.mp4",
                    },
                    {
                        id: "46",
                        category: "3_vorm_in_houdingen",
                        name: "16. De stomp (oefening)",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00047.png",
                        src: "/assets/video/3_vorm_houdingen/47_de_stomp_oefening.mp4",
                        exercise: true,
                    },
                    {
                        id: "47",
                        category: "3_vorm_in_houdingen",
                        name: "16. De stomp (oefening)",
                        subtitle: "Gefilmd vanaf de achterkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00048.png",
                        src: "/assets/video/3_vorm_houdingen/48_de_stomp_oefening_achter.mp4",
                        reverse: true,
                        exercise: true,
                    },
                    {
                        id: "48",
                        category: "3_vorm_in_houdingen",
                        name: "17. Afstrijken hand",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00049.png",
                        src: "/assets/video/3_vorm_houdingen/49_afstrijken_hand.mp4",
                    },
                    {
                        id: "49",
                        category: "3_vorm_in_houdingen",
                        name: "17. Afstrijken hand (oefening)",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00050.png",
                        src: "/assets/video/3_vorm_houdingen/50_afstrijken_hand_oefening.mp4",
                        exercise: true,
                    },
                    {
                        id: "50",
                        category: "3_vorm_in_houdingen",
                        name: "17. Afstrijken hand (oefening)",
                        subtitle: "Gefilmd vanaf de achterkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00051.png",
                        src: "/assets/video/3_vorm_houdingen/51_afstrijken_hand_oefening_achter.mp4",
                        reverse: true,
                        exercise: true,
                    },
                    {
                        id: "50",
                        category: "3_vorm_in_houdingen",
                        name: "18. Kijk door het venster, afsluiting",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00052.png",
                        src: "/assets/video/3_vorm_houdingen/52_kijk_venster_met_afsluiting.mp4",
                    },
                    {
                        id: "51",
                        category: "3_vorm_in_houdingen",
                        name: "18. Kijk door het venster, afsluiting (oefening)",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00053.png",
                        src: "/assets/video/3_vorm_houdingen/53_kijk_venster_met_afsluiting_oefening.mp4",
                        exercise: true,
                    },
                    {
                        id: "52",
                        category: "3_vorm_in_houdingen",
                        name: "18. Kijk door het venster, afsluiting (oefening)",
                        subtitle: "Gefilmd vanaf de achterkant",
                        thumbnail: "/assets/thumbnails/03_vorm_houdingen/thumbnail-00054.png",
                        src: "/assets/video/3_vorm_houdingen/54_kijk_venster_met_afsluiting_oefening_achter.mp4",
                        reverse: true,
                        exercise: true,
                    },
                ],

                /* Qi Gong */

                [
                    {
                        id: "0",
                        category: '4_qi_gong',
                        name: "Qi Gong 1",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/04_qi_gong/thumbnail-00001.png",
                        src: "/assets/video/4_qi_gong/1_qi_gong_1.mp4",
                    },
                    {
                        id: "1",
                        category: '4_qi_gong',
                        name: "Qi Gong 2",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/04_qi_gong/thumbnail-00002.png",
                        src: "/assets/video/4_qi_gong/2_qi_gong_2.mp4",
                    },
                ],

                /* In de stoel */

                [
                    {
                        id: "0",
                        category: '5_in_de_stoel',
                        name: "Stoeloefening",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/06_proeflessen/thumbnail-00001.png",
                        src: "/assets/video/6_proeflessen/proefles_zit_compressed.mp4",
                    },
                ],

                /* Oefeningen */

                [
                    {
                        id: "0",
                        category: '6_oefeningen',
                        name: "Ademhalings oefening",
                        subtitle: "M.b.t. ontspanning",
                        thumbnail: "/assets/thumbnails/05_oefeningen/thumbnail-00001.png",
                        src: "/assets/video/5_oefeningen/1_ademhaling_ontspanning.mp4",
                    },
                    {
                        id: "1",
                        category: '6_oefeningen',
                        name: "Ademhalingsoefening in relatie tot de vorm",
                        subtitle: "M.b.t. de vorm",
                        thumbnail: "/assets/thumbnails/05_oefeningen/thumbnail-00002.png",
                        src: "/assets/video/5_oefeningen/2_ademhaling_rel_vorm.mp4",
                    },
                    {
                        id: "2",
                        category: '6_oefeningen',
                        name: "Bewegingsmeditatie",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/05_oefeningen/thumbnail-00003.png",
                        src: "/assets/video/5_oefeningen/3_bewegings_meditatie.mp4",
                    },
                    {
                        id: "3",
                        category: '6_oefeningen',
                        name: "Loopmeditatie",
                        subtitle: "Gefilmd vanaf de voorkant",
                        thumbnail: "/assets/thumbnails/05_oefeningen/thumbnail-00004.png",
                        src: "/assets/video/5_oefeningen/4_loopmeditatie.mp4",
                    },
                ],
            ];

            let i = 0;
            let categories = [
                'warming_up',
                'complete_vorm',
                'vorm_in_delen',
                'vorm_in_houdingen',
                'qi_gong',
                'in_de_stoel',
                'oefeningen'
            ]

            let catNames = [
                'Warming up',
                'Complete vorm',
                'Vorm in delen',
                'Vorm in houdingen',
                'Qi Gong',
                'In de stoel',
                'Oefeningen'
            ]

            list.forEach(category => {
                let data = { name: catNames[i] }
                projectDb.collection('videos').doc(i + '_' + categories[i]).set(data)

                category.forEach(video => {
                    video.tag = catNames[i]
                    projectDb.collection('videos').doc(i + '_' + categories[i])
                        .collection('videos').doc(video.id).set(video)
                })
                i++
            });
        }
    }
}
</script>