<template>
    <form @submit.prevent=""
        class="sm:bg-white sm:border flex flex-col border-neutral-200 px-3 py-8 sm:p-8 sm:rounded-2xl grow max-w-sm">

        <h2 class="text-lg  text-center font-semibold text-neutral-800 mb-8 sm:mb-2">Lidmaatschap aanvragen</h2>
        <div class="text-center text-green-600  text-sm mb-4">
            <p>Het lidmaatschap kost € 60,- voor een jaar.</p>
        </div>
        <div v-if="!response" class="flex flex-col text-center">
            <p class="text-sm text-neutral-500 mb-1">Zou je graag een lidmaatschap op T'ai Chi voor thuis aanvragen? Vul
                onderstraand formulier in en wij nemen zo snel mogelijk contact me je op.

            </p>
            <div class="flex flex-col transition-opacity relative" :class="{ 'opacity-30': loading }">
                <label class="text-sm mt-4 mb-2 text-left" for="name"> Naam:</label>
                <input type="text" name="name" id="name" placeholder="Naam" v-model="name"
                    class="bg-neutral-50 py-3 px-6 rounded-lg border border-neutral-200 mb-2 text-sm text-neutral-500" />

                <label class="text-sm mt-4 mb-2 text-left" for="name"> E-mailadres:</label>
                <input type="text" name="name" id="name" placeholder="E-mailadres" v-model="email"
                    class="bg-neutral-50 py-3 px-6 rounded-lg border border-neutral-200 mb-2 text-sm text-neutral-500" />

                <p v-if="invalidInput" class="text-xs text-red-500 text-center mt-2">Vul alle velden in en probeer het
                    opnieuw.</p>

                <div v-if="loading" class="loader absolute text-lg w-full h-full flex items-center justify-center">
                    <fa-icon icon="circle-notch" class="animate-spin" />
                </div>
            </div>
            <div class="flex w-full justify-between gap-4">
                <button @click="$router.go(-1)"
                    class="col-span-2 bg-neutral-100 py-3.5 rounded-lg text-neutral-600 font-semibold text-sm mt-4 w-full">Annuleren
                </button>
                <button @click="sendEmail"
                    class="col-span-2 bg-green-500 py-3.5 rounded-lg text-white font-semibold text-sm mt-4 w-full">Vraag
                    versturen
                </button>
            </div>
        </div>
        <div v-if="response === 'success'" class="text-sm text-center">
            <p class="bg-green-600/10 py-2.5 rounded-lg text-green-600 font-medium">Het bericht is successvol
                verstuurd!</p>
            <button @click="$router.push('/')"
                class="col-span-2 bg-neutral-100 py-3.5 rounded-lg text-neutral-600 font-semibold text-sm mt-4 w-full">
                Terug naar home
            </button>
        </div>
        <div v-if="response === 'error'" class="text-sm text-center">
            <p class="bg-red-600/10 py-2.5 rounded-lg text-red-600 font-medium mb-2">Er is iets fout gegaan:</p>
            <p class="text-xs">{{ error }}</p>
        </div>
    </form>
</template>

<script>
import emailjs from '@emailjs/browser';

export default {
    data() {
        return {
            name: '',
            email: '',
            invalidInput: false,
            loading: false,
            response: '',
            error: '',
        }
    },
    computed: {
        getUserData() {
            return this.$store.getters.getUserData
        }
    },
    methods: {
        sendEmail() {
            if (this.name == "" || this.email == "") {
                this.invalidInput = true;
            } else {
                this.loading = true
                var message = this.name + ' zou graag een nieuw lidmaatschap willen afsluiten voor Tai Chi voor thuis.'
                var templateParams = {
                    from_name: this.name,
                    message: message,
                    email: this.email,
                };
                emailjs
                    .send(
                        "taichivoorthuis1",
                        "template_qokuu5h",
                        templateParams,
                        "user_nSWOBeyHcbkMqToAVk44M"
                    )
                    .then(
                        (response) => {
                            console.log("SUCCESS!", response.status, response.text);
                            this.loading = false;
                            this.response = 'success';
                        },
                        (error) => {
                            console.log("FAILED...", error);
                            this.loading = false;
                            this.response = 'fail';
                            this.error = error
                        }
                    );
            }
        },
    }
}
</script>