<template>
    <div class="w-screen h-fit scrollbar-hide flex flex-col items-center" v-if="getVideoFromIndex">
        <div class="overflow-hidden z-50 flex w-full flex-col justify-center items-center bg-neutral-100 pb-4 lg:pb-8">
            <div class="container md:p-8 lg:px-24 md:pb-4 lg:py-8">
                <div class="overflow-hidden md:rounded-2xl relative group">
                    <video :src="getVideoUrl" controls controlslist="nodownload" autoplay @click.right.prevent
                        preload="auto">
                    </video>
                    <div @click="$router.push('/home')"
                        class="bg-white hidden sm:flex absolute opacity-50 md:opacity-25 group-hover:opacity-100 rounded-xl cursor-pointer transition-opacity h-8 md:h-11 aspect-square top-4 left-4 items-center justify-center">
                        <fa-icon icon="arrow-left" class="text-green-800 text-sm md:text-base" />
                    </div>
                </div>
            </div>
            <div class="container flex justify-between items-center px-4 mt-4 md:mt-0 md:px-8 lg:px-24">
                <div class=" flex items-center gap-4">
                    <div @click="$router.push('/home')"
                        class="bg-green-600 text-white sm:hidden group-hover:opacity-100 rounded-xl cursor-pointer transition-opacity h-9 md:h-11 aspect-square top-4 left-4 flex items-center justify-center">
                        <fa-icon icon="arrow-left" class=" text-sm md:text-base" />
                    </div>
                    <div>
                        <h1 class="font-semibold text-sm lg:text-lg text-black">{{ getVideoFromIndex.name }}</h1>
                        <h2 class="text-xs lg:text-sm text-gray-500">{{ getVideoFromIndex.subtitle }}</h2>
                    </div>
                </div>

                <div class=" flex gap-2 items-center">
                    <div class="rounded-full bg-neutral-200 cursor-pointer h-10 aspect-square flex items-center justify-center hover:bg-red-600/10 group  transition-colors"
                        @click="setFavorite">
                        <fa-icon v-if="getIsFavorite" v-cloak icon="heart" class="text-red-600"
                            :class="{ 'animate-vote': favorite }" />
                        <fa-icon v-if="!getIsFavorite" icon="heart"
                            class="text-neutral-400 group-hover:text-red-600 transition-colors" />
                    </div>
                    <p class="bg-green-600/20 text-black text-sm font-medium px-6 py-2.5 rounded-full hidden sm:block">
                        {{ getVideoFromIndex.cat }}</p>
                </div>
            </div>
        </div>
        <the-next-videos :cat="$route.params.category" :id="$route.params.id"></the-next-videos>
    </div>
</template>

<script>
import { projectAuth, projectDb, projectFb } from '../../firebaseConfig.js'
import { defineComponent } from 'vue'
import 'video.js/dist/video-js.css'

import TheNextVideos from "@/components/TheNextVideos.vue"

export default defineComponent({
    data() {
        return {
            cat: null,
            favorite: false
        }
    },
    components: {
        TheNextVideos
    },
    methods: {
        // Toggles saving the video in savedVideos array that is stored in the users profile
        setFavorite() {
            projectAuth.onAuthStateChanged((user) => {
                if (user) {
                    let favorite = this.$route.params.category + '/' + this.$route.params.id
                    let userData = this.getUserData
                    let savedVideos = userData.savedVideos

                    if (savedVideos.some(el => el === favorite)) {
                        projectDb.collection('users').doc(user.uid).update({
                            'userData.savedVideos': projectFb.firestore.FieldValue.arrayRemove(favorite)
                        })
                    } else {
                        projectDb.collection('users').doc(user.uid).update({
                            'userData.savedVideos': projectFb.firestore.FieldValue.arrayUnion(favorite)
                        })
                    }
                }
            })
        }
    },
    computed: {
        getVideoFromIndex() {
            let catFromParams = this.$route.params.category
            let idFromParams = this.$route.params.id
            let videoIndex = this.$store.getters.getVideoIndex

            let result = videoIndex.find(indexCategory => {
                return indexCategory.id === catFromParams
            })

            var video = null

            if (result) {
                var cat = result.name
                video = result.videos[idFromParams];
                video.cat = cat
            }
            return video
        },
        getVideoUrl() {
            let url = this.getVideoFromIndex.src
            return 'https://media.taichivoorthuis.nl/' + url
        },
        getUserData() {
            return this.$store.getters.getUserData
        },
        getIsFavorite() {
            let favorite = this.$route.params.category + '/' + this.$route.params.id
            let userData = this.getUserData
            let savedVideos = userData.savedVideos

            return savedVideos.some(el => el === favorite) ? true : false
        }
    },
    mounted() {
        if (this.getVideoFromIndex) {
            document.title = "T'ai Chi voor thuis - " + this.getVideoFromIndex.name;
        } else {
            document.title = "T'ai Chi voor thuis -  Afspelen"
        }

    }
})
</script>