<template>
    <div class="flex items-center gap-3 px-4 md:py-6" v-if="getUserData">
        <div
            class="rounded-full h-12 aspect-square bg-white overflow-clip flex items-center justify-center text-green-700">
            <img v-if="getUserData" :src="getUserAvatar" class="object-cover" alt="User avatar">
        </div>
        <div>
            <p class="text-neutral-600 text-xs">Welkom</p>
            <p class="font-bold text-base">{{ getUserData.displayName }}
            </p>
        </div>
    </div>
    <!-- Skeleton loader -->
    <div v-else class="flex items-center gap-3 px-4 animate-pulse">
        <div
            class="rounded-full h-12 aspect-square bg-neutral-200 overflow-clip flex items-center justify-center text-neutral-400">
            <fa-icon class="animate-spin" icon="circle-notch" />
        </div>
        <div>
            <div class="border-4 w-12 mb-2 border-neutral-200 rounded-xl"></div>
            <div class="border-4 w-24 border-neutral-400 rounded-xl"></div>

        </div>
    </div>
</template>
<script>
import { createAvatar } from '@dicebear/core';
import { initials } from '@dicebear/collection';

export default {
    computed: {
        getUserData() {
            return this.$store.getters.getUserData
        },
        getUserAvatar() {
            const firstLetters = this.getUserData.name.slice(0, 1) + this.getUserData.surname.slice(0, 1)
            return createAvatar(initials, {
                size: 128,
                seed: firstLetters,
            }).toDataUriSync()
        }
    },
}
</script>