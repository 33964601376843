<template>
    <div v-if="getUserData" class="flex">
        <the-navbar></the-navbar>
        <div class="w-full flex flex-col h-dvh overflow-y-scroll items-center lg:items-center">
            <div
                class="  lg:bg-green-600/20 w-full relative  lg:h-40 mb-4  lg:mb-28 flex justify-center items-start pt-4 ">
                <div class="container relative h-full px-32 max-w-screen-xl flex items-center justify-center 2xl:block">
                    <div
                        class="w-24 md:w-32 lg:w-40 lg:absolute aspect-square rounded-full bg-green-500 md:-bottom-16 lg:-bottom-20 overflow-hidden drop-shadow-lg flex items-center justify-center">
                        <img v-if="getUserData" :src="getUserAvatar" alt="user profile picture"
                            class="object-cover w-full">

                    </div>
                </div>
            </div>

            <div
                class="px-4 lg:px-32 grid md:grid-cols-1 2xl:grid-cols-2 max-w-screen-xl gap-y-5 lg:gap-y-6 gap-x-7 items-start text-center lg:text-left pb-6 sm:pb-0">
                <div
                    class="container flex items-center justify-between 2xl:justify-start gap-8 w-full 2xl:col-span-2 order-1 2xl:order-none">
                    <div class="text-start">
                        <h2 class="text-lg font-semibold">{{ getUserData.name + ' ' + getUserData.surname }}</h2>
                        <p class="text-sm">{{ getUserData.email }}</p>
                    </div>
                    <button @click="$router.push('/logout')"
                        class="text-sm flex items-center gap-2 px-6 py-3 font-medium bg-red-600/5 rounded-md text-red-600">
                        <p>Uitloggen</p>
                        <fa-icon icon="sign-out-alt" />
                    </button>
                </div>

                <div
                    class="form border grid grid-cols-6 gap-3 lg:gap-4 border-neutral-200 p-8 rounded-2xl order-3 2xl:order-none text-start">
                    <label for="displayName" class="col-span-6 text-sm">Gebruikersnaam:</label>
                    <input type="text"
                        class="bg-neutral-50 py-3 px-6 rounded-lg border border-neutral-200 col-span-6 mb-2 text-sm placeholder:text-black focus:outline-green-500"
                        :value="getUserData.displayName"
                        @focusout="$event.target.value = handleDataChange($event.target.value, 'displayName')"
                        @click="$event.target.value = ''" />

                    <label for="name" class="col-span-3 text-sm">Voornaam:</label>
                    <label for="surname" class="col-span-3 text-sm">Achternaam:</label>
                    <input type="text" name="name"
                        class="bg-neutral-50 py-3 px-6 rounded-lg border border-neutral-200 col-span-3 mb-2 text-sm placeholder:text-black focus:outline-green-500"
                        :value="getUserData.name"
                        @focusout="$event.target.value = handleDataChange($event.target.value, 'name')"
                        @focusin="$event.target.value = ''" />
                    <input type="text" name="surname"
                        class="bg-neutral-50 py-3 px-6 rounded-lg border border-neutral-200 col-span-3 mb-2 text-sm placeholder:text-black focus:outline-green-500"
                        :value="getUserData.surname"
                        @focusout="$event.target.value = handleDataChange($event.target.value, 'surname')"
                        @click="$event.target.value = ''" />
                    <label for="displayName" class="col-span-6 text-sm">E-mailadres:</label>
                    <input type="email"
                        class="bg-neutral-50 py-3 px-6 rounded-lg border border-neutral-200 col-span-6 mb-2 text-sm placeholder:text-neutral-400"
                        :placeholder="getUserData.email" disabled />
                    <label for="displayName" class="col-span-6 text-sm">Wachtwoord:</label>
                    <input type="email"
                        class="bg-neutral-50 py-3 px-6 rounded-lg border border-neutral-200 col-span-4 mb-2 text-sm placeholder:text-neutral-400"
                        placeholder="*************" disabled />
                    <button class="col-span-2 bg-red-600/10 rounded-lg mb-2 text-red-600 font-medium text-sm"
                        @click="passReset = true">Resetten</button>
                </div>
                <div v-if="getUserSubscriptionValid === true"
                    class="p-[1px] bg-gradient-to-bl from-yellow-600 rounded-[12px] to-gray-400 order-2 2xl:order-none">
                    <div class="w-full h-full flex flex-col gap-1.5 bg-white py-4 px-8 lg:p-8 rounded-[11px]">
                        <p class="text-sm">Tai Chi voor thuis</p>
                        <h2
                            class="text-xl font-semibold inline lg:w-fit bg-gradient-to-bl from-yellow-600 rounded-2xl to-gray-400 text-transparent bg-clip-text ">
                            Premium gebruiker</h2>
                        <p class="text-sm">Geldig tot: {{ getSubscriptionEndDate }}</p>

                    </div>
                </div>
                <div v-if="getUserSubscriptionValid === false"
                    class="p-[1px] bg-gray-50 py-4 px-8 lg:p-8 rounded-[11px]">
                    <p class="text-sm font-medium text-red-600">Je lidmaatschap van Tai Chi voor thuis is verlopen.</p>
                    <p class="text-sm mt-1">Verlopen op: {{ getSubscriptionEndDate }}</p>
                    <button @click="$router.push('/request-subscription')"
                        class="py-3 px-6 mt-4 rounded-lg text-sm font-medium text-white bg-green-500 cursor-pointer">Nu
                        verlengen! <fa-icon icon="arrow-right" class="ml-2" /></button>
                </div>
            </div>
        </div>
        <transition name="fade">
            <the-reset-pass-modal v-if="passReset" @close="passReset = false"></the-reset-pass-modal>
        </transition>
    </div>
</template>
<script>
import TheNavbar from '@/components/TheNavbar.vue';
import TheResetPassModal from '@/components/TheResetPassModal.vue';
import { projectDb, projectAuth } from '../../firebaseConfig.js';
import { format, fromUnixTime } from 'date-fns';
import { createAvatar } from '@dicebear/core';
import { initials } from '@dicebear/collection';

export default {
    components: { TheNavbar, TheResetPassModal },
    data() {
        return {
            user: '',
            passReset: false
        }
    },
    computed: {
        getUserData() {
            return this.$store.getters.getUserData
        },
        getUserSubscriptionValid() {
            return this.$store.getters.getSubValid
        },
        getSubscriptionEndDate() {
            let endDate = fromUnixTime(this.$store.getters.getEndDate)
            return format(endDate, 'dd-MM-yyyy');
        },
        getUserAvatar() {
            const firstLetters = this.getUserData.name.slice(0, 1) + this.getUserData.surname.slice(0, 1)
            return createAvatar(initials, {
                size: 128,
                seed: firstLetters,
            }).toDataUriSync()
        }
    },
    methods: {
        handleDataChange(input, record) {
            //changes the db userdata after the change of input 
            projectAuth.onAuthStateChanged((user) => {
                if (input != '' && input.length > 1) {
                    if (user) {
                        if (record === 'name') {
                            this.user = user
                            const uid = user.uid;
                            const docRef = projectDb.collection('users').doc(uid);
                            docRef.update({
                                'userData.name': input
                            }).then(
                                this.$store.dispatch('loadUser')
                            )
                        }

                        if (record === 'displayName') {
                            this.user = user
                            const uid = user.uid;
                            const docRef = projectDb.collection('users').doc(uid);
                            docRef.update({
                                'userData.displayName': input
                            }).then(
                                this.$store.dispatch('loadUser')
                            )
                        }

                        if (record === 'surname') {
                            this.user = user
                            const uid = user.uid;
                            const docRef = projectDb.collection('users').doc(uid);
                            docRef.update({
                                'userData.surname': input
                            }).then(
                                this.$store.dispatch('loadUser')
                            )
                        }
                    }
                }
            });
            return this.getUserData[record]
        }
    },
    mounted() {
        this.user = this.$store.getters.getUser
        document.title = "T'ai Chi voor thuis - Mijn account";
    }
}
</script>