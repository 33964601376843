<template>
    <div class="h-dvh w-screen fixed z-20 flex items-end md:items-center justify-center">
        <div class="wall bg-black/50 z-20 w-full h-full absolute cursor-pointer" @click="$emit('close')"></div>
        <div class="bg-white rounded-t-2xl md:rounded-2xl p-7 max-w-md flex flex-col z-30 drop-shadow-xl">
            <div>
                <div class="flex items-center justify-between mb-3">
                    <h2 class="font-semibold text-lg">Gebruiker verwijderen</h2>
                    <fa-icon @click="$emit('close')" icon="times" class="text-lg cursor-pointer" />
                </div>
                <p class="text-sm text-neutral-500 mb-6 pt-2 leading-6">
                    Weet je zeker dat je <span class="font-semibold text-black bg-green-600/10 p-0.5 px-2 rounded-full">
                        {{ user.name + ' ' + user.surname }}
                    </span> wilt verwijderen? Als deze gebruiker nog een actief lidmaatschap heeft zal die de toegang
                    hiertoe verliezen.</p>
                <div class="grid grid-cols-2 gap-4">

                    <button @click="$emit('close')"
                        class="col-span-1  py-3 px-6 rounded-lg mb-2 bg-green-600 text-white font-semibold text-sm">Annuleren</button>
                    <button @click="removeUser"
                        class="col-span-1  py-3 px-6 rounded-lg mb-2 border border-neutral-500 text-neutral-700 font-semibold text-sm">Verwijderen</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { projectDb } from '../../../firebaseConfig.js';

export default {
    props: ['user'],
    methods: {
        removeUser() {
            const uid = this.user.uid
            let userRef = projectDb.collection('users').doc(uid);
            let expRef = projectDb.collection('exp_dates').doc(uid);

            userRef.delete()
            expRef.delete()
        }
    }
}
</script>
