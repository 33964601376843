<template>
    <div class="flex h-dvh w-screen">
        <the-navbar></the-navbar>
        <div class="h-full w-full">
            <the-contact-form></the-contact-form>
        </div>
    </div>
</template>

<script>
import TheContactForm from "@/components/TheContactForm.vue"
import TheNavbar from "@/components/TheNavbar.vue"

export default {
    components: { TheContactForm, TheNavbar },
    mounted() {
        document.title = "T'ai Chi voor thuis - Contact";
    }
}
</script>