<template>
    <div class="flex items-center gap-2 cursor-pointer select-none mb-8" @click="open = !open">
        <div class="w-8 h-8 bg-green-600/20 flex items-center justify-center rounded-full">
            <fa-icon icon="chevron-down" class="transition-all" :class="{ '-rotate-90': !open }" />
        </div>
        <h2 class="font-bold text-sm lg:text-base">{{ category.name }}</h2>
        <p class="text-neutral-500">({{ category.videos.length }})</p>
    </div>
    <Collapse :when="open" class="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 my-4 gap-3 lg:gap-8">
        <template v-for="video in getSortedList" :key="video">
            <the-thumbnail-comp :video="video" :category_id="category.id"></the-thumbnail-comp>
        </template>
    </Collapse>
</template>
<script>
import TheThumbnailComp from './TheThumbnailComp.vue';
import { Collapse } from 'vue-collapsed'


export default {
    components: { TheThumbnailComp, Collapse },
    props: ['category'],
    data() {
        return {
            open: false,
        }
    },
    computed: {
        getSortedList() {
            let list = this.category.videos
            return list.sort((a, b) => a.id - b.id);
        }
    },
    watch: {
        open() {

            localStorage.setItem(this.category.id + "_open", this.open)
        }
    },
    async beforeCreate() {
        let isOpen = await localStorage.getItem(this.category.id + "_open")
        this.open = (isOpen === 'true')
    },
}
</script>
