<template>
    <div class="h-screen w-screen fixed z-50 flex items-end sm:items-center justify-center">
        <div class="wall bg-black/50 z-20 w-full h-full absolute cursor-pointer" @click="handleClose"></div>
        <div class="bg-white rounded-t-2xl sm:rounded-2xl p-7 sm:max-w-lg flex flex-col z-30 drop-shadow-xl">
            <h2 class="font-semibold text-lg mb-4"><span class="mr-2">🎉</span> T'ai Chi Voor Thuis 2.0!</h2>
            <p class="text-xs sm:text-sm text-neutral-500 mb-5 sm:mb-7">Welkom op de nieuwe versie van Tai Chi voor
                Thuis! <br class="hidden sm:block">We nemen
                graag de
                nieuwe features met de door:</p>

            <div class="w-full sm:px-5 py-4  sm:bg-neutral-50 rounded-lg flex items-center mb-3 sm:mb-5">
                <fa-icon icon="tablet-screen-button" class="mr-3 sm:mr-5 text-2xl aspect-square text-green-600" />
                <div class="text-">
                    <p class="text-sm font-medium text-green-600 leading-6">Nu op al je apparaten</p>
                    <p class="text-xs">Beschikbaar voor mobiel, tablet en PC.</p>
                </div>
            </div>

            <div class="w-full sm:px-5 py-4  sm:bg-neutral-50 rounded-lg flex items-center mb-3 sm:mb-5">
                <fa-icon icon="object-group" class="mr-3 sm:mr-5 text-2xl aspect-square text-green-600" />
                <div class="text-">
                    <p class="text-sm font-medium text-green-600 leading-6">Vernieuwde gebruikersomgeving</p>
                    <p class="text-xs">Voor een groter gebruikersgemak.</p>
                </div>
            </div>

            <div class="w-full sm:px-5 py-4  sm:bg-neutral-50 rounded-lg flex items-center mb-8">
                <fa-icon icon="heart" class="mr-3 sm:mr-5 text-2xl aspect-square text-green-600" />
                <div class="text-">
                    <p class="text-sm font-medium text-green-600 leading-6">Favoriete video's opslaan</p>
                    <p class="text-xs">Sla video’s op om ze makkelijk terug te vinden.</p>
                </div>
            </div>

            <button @click="handleClose"
                class="col-span-2  py-3 px-6 rounded-lg mb-2 bg-green-600 hover:bg-green-700 transition-colors text-white font-semibold text-sm">Sluiten</button>
        </div>
    </div>
</template>

<script>
import { projectDb, projectAuth } from '../../firebaseConfig.js';

export default {
    emits: ['close'],
    methods: {
        handleClose() {
            projectAuth.onAuthStateChanged(async (user) => {
                if (user) {
                    await projectDb.collection('users').doc(user.uid).update({
                        'userData.dismissedWelcome': true
                    })
                    this.$emit('close')
                }
            });
        }
    }
}
</script>
