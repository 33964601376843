import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

// Testing database
const firebaseConfig = {
  apiKey: "AIzaSyC2n8wsIRb4Gk_ouNYRH_uZbCQj5yK_sio",
  authDomain: "taichi-testing.firebaseapp.com",
  projectId: "taichi-testing",
  storageBucket: "taichi-testing.appspot.com",
  messagingSenderId: "399163269475",
  appId: "1:399163269475:web:f7c699292028f131167356",
};

firebase.initializeApp(firebaseConfig);
const secondaryFb = firebase.initializeApp(firebaseConfig, "Secondary");

const projectAuth = firebase.auth();
const projectDb = firebase.firestore();
const projectFb = firebase;

export { projectAuth, projectDb, projectFb, secondaryFb };
