<template>
    <div class="wrapper cursor-pointer" v-if="video" @click="ClickAction"
        :class="{ 'opacity-100 cursor-not-allowed': category_id != '1_complete_vorm' }">
        <div class="relative w-fit aspect-video h-30 ">
            <img :src="getThumbnailUrl"
                class="h-full w-full object-cover rounded-2xl pointer-events-none transition-all"
                :class="{ '!ring-0': category_id != '1_complete_vorm' }">
            <div class="icon-wrapper absolute top-3 right-3 flex gap-2 opacity-75 text-xs">
                <fa-icon v-if="video.reverse" class="bg-green-600 rounded-lg text-white p-2" icon="rotate-left" />
                <fa-icon v-if="video.exercise" class="bg-green-600 rounded-lg text-white p-2" icon="dumbbell" />
                <div v-if="video.twoTimes"
                    class="bg-green-600 text-white font-bold text-sm rounded-xl aspect-square h-10 flex justify-center items-center">
                    2X</div>
            </div>
        </div>
        <div class="mt-2.5">
            <p class="font-semibold text-xs lg:text-sm text-nowrap truncate">{{ video.name }}</p>
            <p class="text-[12px] text-neutral-500">{{ video.subtitle }}</p>
        </div>
    </div>
</template>
<script>

export default {
    props: ['video', "category_id"],
    computed: {
        getThumbnailUrl() {
            let url = this.video.thumbnail
            return 'https://media.taichivoorthuis.nl/' + url
        },
        constructVideoUrl() {
            return '/play/' + this.category_id + '/' + this.video.id

        },

    },
    methods: {
        ClickAction() {
            this.$router.push(this.constructVideoUrl)
        }
    },
}
</script>