<template>
    <div
        class="w-screen h-screen flex flex-col justify-center sm:flex-row items-center sm:bg-[url('../assets/wall.jpg')] bg-cover bg-center">
        <the-renew-sub-form v-if="user === true"></the-renew-sub-form>
        <the-request-sub-form v-if="user === false"></the-request-sub-form>
        <div v-if="user === null" class="bg-white rounded-2xl border-neutral-200 p-5">
            <fa-icon icon="circle-notch" class="animate-spin text-2xl text-neutral-500" />
        </div>
    </div>
</template>

<script>
import TheRequestSubForm from "@/components/request-sub/TheRequestSubForm"
import TheRenewSubForm from "@/components/request-sub/TheRenewSubForm"
import { projectAuth } from "../../firebaseConfig.js"

export default {
    components: { TheRequestSubForm, TheRenewSubForm },
    data() {
        return {
            user: null
        }
    },
    async beforeMount() {
        await projectAuth.onAuthStateChanged((user) => {
            if (user) {
                this.user = true
            } else {
                this.user = false

            }
        });
    },
    mounted() {
        document.title = "T'ai Chi voor thuis";
    }
}
</script>