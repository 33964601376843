<template>
    <div class="animate-pulse mt-1">
        <div v-for="i in counter" class="rounded-full border-[8px] even:w-56 even:border-neutral-300 odd:w-36 mb-12"
            :key="i"></div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            counter: 7
        }
    },
}
</script>