<template>
    <div v-if="getTimestamp">
        <div v-if="getDaysLeft > 0" class="sm:bg-white py-3.5 px-5 flex items-center sm:justify-between justify-center"
            :class="getDaysLeft <= 30 ? 'rounded-t-2xl' : 'rounded-2xl'">
            <div
                class="flex items-center gap-3 border sm:border-none border-neutral-200 justify-center sm:flex-col sm:gap-0 sm:items-start bg-white sm:bg-transparent px-4 py-2 sm:p-0 rounded-2xl">
                <p class="text-sm text-black">Tijd resterend:</p>
                <h3 class="text-2xl sm:text-3xl font-extrabold my-1">{{ getDaysLeft }}</h3>
                <p class="text-sm text-black">dagen</p>
            </div>
            <div class="hidden sm:block">
                <RadialProgress :diameter="100" :completed-steps="getDaysLeft" :total-steps="365"
                    inner-stroke-color="#e5e5e5" :start-color="getGraphColor" :stop-color="getGraphColor"
                    :strokeWidth="13" :inner-stroke-width="13">
                    <span>{{ getPercentage }}</span>
                </RadialProgress>
            </div>
        </div>
        <div v-else
            class="sm:bg-white py-6 px-5 flex flex-col items-center sm:justify-between justify-center rounded-t-2xl text-red-600">
            <fa-icon icon="triangle-exclamation" class='mr-1  text-2xl mb-2' />
            <p class="font-medium text-sm lg:text-base">Je lidmaatschap is verlopen</p>
        </div>
        <div v-if="getDaysLeft <= 30" @click="$router.push('/request-subscription')"
            class="py-3 hidden sm:flex px-6 items-center font-medium text-white justify-between bg-green-500 cursor-pointer rounded-b-2xl">
            <p>Nu verlengen</p>
            <fa-icon icon="arrow-right" />
        </div>
    </div>
    <div v-else
        class="sm:bg-neutral-200/40 rounded-2xl px-5 py-3.5 flex items-center sm:justify-between justify-center animate-pulse">
        <div
            class="flex items-center gap-3 justify-center sm:flex-col sm:gap-0 sm:items-start bg-white sm:bg-transparent px-4 py-2 sm:p-0 rounded-2xl">
            <div class="border-neutral-300 border-4 w-16 rounded"></div>
            <div class="border-4 w-24 border-neutral-400  rounded my-3"></div>
            <div class="border-neutral-300 border-4 w-12 rounded"></div>
        </div>
        <div class="animate-spin hidden sm:block">
            <RadialProgress :diameter="100" :completed-steps="80" :total-steps="100" inner-stroke-color="#e4e4e7"
                start-color="#d4d4d4" stop-color="#d4d4d4" :strokeWidth="13" :inner-stroke-width="13">
            </RadialProgress>
        </div>
    </div>
</template>
<script>
import RadialProgress from "vue3-radial-progress";
import { differenceInDays, fromUnixTime } from "date-fns";

export default {
    components: {
        RadialProgress
    },
    computed: {
        getPercentage() {
            return ((100 / 365) * this.getDaysLeft).toFixed(0) + '%'
        },

        getDaysLeft() {
            let renewDate = fromUnixTime(this.getTimestamp) || null
            let elapsedDays = differenceInDays(renewDate, new Date)
            return elapsedDays
        },

        getTimestamp() {
            return this.$store.getters.getEndDate
        },
        getGraphColor() {
            return this.getDaysLeft > 30 ? '#22c55e' : '#EF4444'
        }
    }
}
</script>