<template>
  <div class="flex relative overflow-hidden">
    <the-navbar></the-navbar>
    <div class="flex flex-col w-full relative p-5 lg:px-8 lg:py-10 h-screen overflow-y-scroll"
      v-if="getSubValid === true">
      <div class="flex w-full justify-end md:justify-between items-center mb-8">
        <h1 class="hidden lg:block text-lg lg:text-2xl font-bold">{{ getTitle }}</h1>
        <!-- Searchbar -->
        <div class="relative flex items-center w-full lg:w-auto pl-16 md:pl-0">
          <input type="text"
            class="rounded-full py-3.5 grow lg:grow-0 lg:w-72 px-6 text-sm focus:outline-green-600 bg-neutral-100"
            placeholder="Zoeken in video's..." v-model="searchquery">
          <fa-icon icon="magnifying-glass" class="absolute right-6" />
        </div>
      </div>
      <!-- Regular video chapter view -->
      <div class="unqueried" v-if="!searchquery">
        <the-video-chapter v-for="category in getVideoIndex" :key="category.id"
          :category="category"></the-video-chapter>
        <the-home-skeleton v-if="getVideoIndex.length === 0"></the-home-skeleton>
      </div>
      <!-- Search results replace regular chapter view of video's when string is entered -->
      <div v-else class="queried grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 my-4 gap-3 lg:gap-8">
        <the-thumbnail-comp v-for="video in getQueriedIndex" :video="video" :key="video"></the-thumbnail-comp>
        <div v-if="searchquery && getQueriedIndex.length == 0">
          <p>Geen video's gevonden voor: <span class="font-semibold">{{ searchquery }}</span></p>
        </div>
      </div>
      <the-legend-comp></the-legend-comp>
    </div>
    <div v-if="getSubValid === false" class="w-full h-screen flex items-center justify-center mx-3">
      <div class=" bg-gray-50 py-4 px-8 lg:p-8 rounded-2xl flex flex-col items-center text-center">
        <fa-icon class="text-red-600 text-2xl mb-2" icon="triangle-exclamation" />
        <p class="text-sm font-medium text-red-600">Je lidmaatschap van Tai Chi
          voor thuis is verlopen.</p>
        <button @click="$router.push('/request-subscription')"
          class="py-3 px-6 mt-4 rounded-lg text-sm font-medium text-white bg-green-500 cursor-pointer">Nu
          verlengen! <fa-icon icon="arrow-right" class="ml-2" /></button>
      </div>
    </div>
  </div>
</template>

<script>
import TheHomeSkeleton from '@/components/TheHomeSkeleton.vue';
import TheThumbnailComp from '@/components/TheThumbnailComp.vue';
import TheVideoChapter from '@/components/TheVideoChapter.vue';
import TheNavbar from "@/components/TheNavbar.vue"
import TheLegendComp from "@/components/TheLegendComp.vue"


export default {
  name: 'HomeView',
  components: { TheVideoChapter, TheHomeSkeleton, TheThumbnailComp, TheNavbar, TheLegendComp },
  data() {
    return {
      searchquery: '',
    }
  },
  computed: {
    getTitle() {
      return this.searchquery ? 'Zoekresultaten:' : "Alle video's";
    },
    getVideoIndex() {
      let videoIndex = this.$store.getters.getVideoIndex
      return videoIndex
    },
    getSubValid() {
      return this.$store.getters.getSubValid
    },
    // Bit of a complicated search function because of the nested structure of the video index.
    getQueriedIndex() {

      let videoIndex = this.$store.getters.getVideoIndex
      let searchquery = this.searchquery.toLowerCase()
      let result = []

      videoIndex.forEach(category => {
        let videos = category.videos;

        videos.forEach(video => {
          let name = video.name.toLowerCase()
          let subtitle = video.subtitle.toLowerCase()
          let tag = video.tag.toLowerCase()

          if (name.includes(searchquery) || subtitle.includes(searchquery) || tag.includes(searchquery)) {
            result.push(video)
          }
        });
      });

      return result
    },
  },
  mounted() {
    document.title = "T'ai Chi voor thuis";
  }
}
</script>
