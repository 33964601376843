<template>
    <div class="h-screen w-screen flex items-center justify-center">
        <div class="text-center flex flex-col gap-2 sm:gap-4 items-center mx-3 md:mx-0">
            <h1 class="text-5xl sm:text-8xl font-black text-green-600">404</h1>
            <h2 class=" text-xl sm:text-2xl font-semibold text-green-800">Pagina niet gevonden</h2>
            <p class="max-w-screen-sm text-sm sm:text-base">Oeps! Deze pagina bestaat niet (meer) of is verplaatst. Geen
                zorgen, klik op de
                knop hieronder om terug
                te gaan naar de homepage.</p>
            <button @click="$router.push('/')"
                class="col-span-1 mt-2 py-3 px-6 rounded-lg mb-2 bg-green-600 text-white font-semibold text-sm hover:bg-green-700 transition-colors w-fit">Terug
                naar de homepage</button>
        </div>
    </div>
</template>
<script>
export default {

    mounted() {
        document.title = "T'ai Chi voor thuis - 404";
    }
}
</script>
