<template>
    <div @click="open = !open"
        class="fixed cursor-pointer text-sm right-8 bottom-8 p-4 bg-white border border-neutral-200 rounded-2xl shadow-xl">
        <p class="font-semibold flex items-center gap-2">Legenda <fa-icon v-if="!open" icon="plus"
                class="w-4 h-4" /><fa-icon v-else icon="minus" class="w-4 h-4" /></p>
        <Collapse :when="open"
            class="grid grid-cols-1 md:grid-cols-2 items-center gap-x-6 gap-y-2 mt-3 text-xs auto-cols-min">
            <p>
                <fa-icon class="rounded-xl text-green-600 mr-2" icon="rotate-left" />
                Gefilmd vanaf de achterkant
            </p>
            <p>
                <fa-icon class="rounded-xl text-green-600 mr-2" icon="dumbbell" />
                Oefeningen
            </p>
            <div class="flex items-center">
                <p class="text-green-600 font-bold text-sm rounded-xl flex justify-center items-center mr-2">
                    2X</p>
                <p>Oefening speelt 2 keer af.</p>
            </div>
            <p>
                <fa-icon class="bg-white rounded-xl bg-red-600/10 text-red-600 mr-2" icon="heart" />
                Favoriete video.
            </p>
        </Collapse>
    </div>
</template>

<script>
import { Collapse } from 'vue-collapsed'

export default {
    components: { Collapse },
    data() {
        return {
            open: false
        }
    }
}
</script>