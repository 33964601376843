<template>
    <div v-if="getUserData" class="sm:bg-neutral-50 w-full h-full flex justify-center text-left sm:items-center">
        <form @submit.prevent=""
            class="sm:bg-white sm:border flex flex-col border-neutral-200 px-3 py-8 sm:p-8 sm:rounded-2xl grow max-w-sm">

            <h2 class="text-lg  text-center font-semibold text-neutral-800 mb-8 sm:mb-4">Stel een vraag</h2>

            <div v-if="!response" class="flex flex-col">
                <p class="text-sm text-neutral-500 mb-1">Heeft u een vraag of opmerking over Tai Chi voor thuis? Vul
                    onderstaand formulier in en wij nemen z.s.m. contact met u op.</p>
                <div class="flex flex-col transition-opacity relative" :class="{ 'opacity-30': loading }">
                    <label class="text-sm mt-4 mb-2 text-left" for="name"> Naam:</label>
                    <input type="text" name="name" id="name"
                        class="bg-neutral-50 py-3 px-6 rounded-lg border border-neutral-200 mb-2 text-sm text-neutral-500"
                        :value="getUserData.name + ' ' + getUserData.surname" disabled />
                    <label class="text-sm mt-4 mb-2 text-left" for="name"> Bericht:</label>
                    <textarea v-model="message"
                        class="bg-neutral-50 py-3 px-6 rounded-lg border border-neutral-200 mb-2 text-sm placeholder:text-neutral-300 focus:outline-green-500"
                        :class="{ 'border-red-500': invalidInput }" rows="7"></textarea>

                    <p v-if="invalidInput" class="text-xs text-red-500 text-center mt-2">Vul een bericht in en probeer
                        het
                        opnieuw</p>

                    <div v-if="loading" class="loader absolute text-lg w-full h-full flex items-center justify-center">
                        <fa-icon icon="circle-notch" class="animate-spin" />
                    </div>
                </div>
                <button @click="sendEmail"
                    class="col-span-2 bg-green-500 py-3.5 rounded-lg text-white font-semibold text-sm mt-4">Vraag
                    versturen
                </button>
            </div>
            <div v-if="response === 'success'" class="text-sm text-center">
                <p class="bg-green-600/10 py-2.5 rounded-lg text-green-600 font-medium">Het bericht is successvol
                    verstuurd!</p>
            </div>
            <div v-if="response === 'error'" class="text-sm text-center">
                <p class="bg-red-600/10 py-2.5 rounded-lg text-red-600 font-medium mb-2">Er is iets fout gegaan:</p>
                <p class="text-xs">{{ error }}</p>
            </div>
        </form>
    </div>
</template>

<script>
import emailjs from '@emailjs/browser';

export default {
    data() {
        return {
            message: '',
            invalidInput: false,
            loading: false,
            response: '',
            error: ''
        }
    },
    computed: {
        getUserData() {
            return this.$store.getters.getUserData
        }
    },
    methods: {
        sendEmail() {
            if (this.message == "" || this.message.length < 5) {
                this.invalidInput = true;
            } else {
                this.loading = true

                var templateParams = {
                    from_name: this.getUserData.name + ' ' + this.getUserData.surname,
                    message: this.message,
                    email: this.getUserData.email,
                };
                emailjs
                    .send(
                        "taichivoorthuis1",
                        "template_qokuu5h",
                        templateParams,
                        "user_nSWOBeyHcbkMqToAVk44M"
                    )
                    .then(
                        (response) => {
                            console.log("SUCCESS!", response.status, response.text);
                            this.loading = false;
                            this.response = 'success';
                        },
                        (error) => {
                            console.log("FAILED...", error);
                            this.loading = false;
                            this.response = 'fail';
                            this.error = error
                        }
                    );
            }
        },
    }
}
</script>