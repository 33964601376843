<template>
    <div class="wrapper flex flex-col items-center relative overflow-clip">
        <div class="container h-screen pt-4 sm:pt-8">
            <div class="header flex items-center px-4 justify-between">
                <img src="../assets/logo.png" alt="tai chi chuan nijmegen logo" class=" w-24 sm:w-36">

                <div class="flex justify-between gap-4">
                    <button class="hidden sm:block py-2.5 sm:px-8 font-medium text-green-600 hover:underline"
                        @click="$router.push('/login')">Inloggen</button>
                    <button class="sm:hidden text-sm py-2.5 sm:px-8 font-medium text-green-600 hover:underline"
                        @click="$router.push('/login')">naar
                        Inloggen</button>
                    <button @click="$router.push('/request-subscription')"
                        class="py-2.5 px-8 hidden sm:block font-medium text-white bg-green-600 hover:bg-green-600 transition-colors rounded-full">Nu
                        aanmelden!</button>
                </div>
            </div>
            <div class="grid md:grid-cols-2 sm:gap-1 lg:gap-4 xl:gap-0 h-3/4 sm:h-full mx-4">
                <div class=" hiddenh-full w-full text-center md:text-left flex flex-col justify-center">
                    <h1 class="text-3xl sm:text-4xl lg:text-5xl xl:text-6xl font-bold text-green-600">Stream nu </h1>
                    <h1 class="text-3xl sm:text-4xl lg:text-5xl xl:text-6xl font-bold">Onbeperkt T'ai Chi</h1>
                    <p class="my-6 lg:my-10 text-sm lg:text-base max-w-2xl">T’ai Chi voor thuis biedt voor een vast
                        bedrag per
                        jaar onbeperkt T’ai
                        Chi
                        video’s
                        streamen aan.
                        Je
                        krijgt een jaar lang onbeperkt toegang tot onder andere; warming up, de 24 Vorm (Korte Vorm), Qi
                        Gong oefeningen en loopoefeningen.</p>
                    <div
                        class="flex flex-col gap-2 sm:flex-row justify-start items-center md:justify-start sm:justify-center z-50">
                        <button @click="$router.push('/request-subscription')"
                            class="py-2.5 px-8 text-sm lg:text-base font-medium text-white bg-green-600 hover:bg-green-600 transition-colors rounded-full w-fit">Nu
                            aanmelden!</button>
                        <button @click="scrollToElement"
                            class="py-2.5 px-8 font-medium text-sm lg:text-base text-green-600 hover:underline">Of
                            bekijk de
                            trailer</button>
                    </div>
                </div>
                <div class="hidden md:flex flex-col items-end justify-center z-20">
                    <img class="sm:scale-100 md:scale-150 lg:scale-125 2xl:scale-115 md:translate-x-32 xl:translate-x-40 xl:max-w-[700px]"
                        rel="prefetch" alt="Mockup video playing on screen" src="@/assets/mockup.png">
                </div>
            </div>
            <div
                class="absolute w-full flex justify-center left-0 bottom-0 h-1/4 md:h-1/3 xl:h-1/2 bg-[url('~@/assets/wallSwirlTop.png')] bg-cover">
                <div @click="scrollToElement"
                    class="absolute cursor-pointer z-50 bottom-12 bg-white rounded-full aspect-square p-2 h-12 flex items-center justify-center text-lg animate-bounce drop-shadow-xl">
                    <fa-icon icon="chevron-down" />
                </div>
            </div>
        </div>
    </div>
    <div class="wrapper flex flex-col items-center relative" ref="section">
        <div
            class="absolute w-full left-0 top-0 h-2/3 overflow-visible bg-[url('~@/assets/wallSwirlBottom.png')] bg-bottom bg-cover -z-10">
        </div>
        <div class="h-screen flex flex-col items-center w-full justify-between">
            <div class="container">
                <div class="h-full  bg-red-500"></div>
            </div>
            <div class="w-full max-w-screen-xl flex flex-col justify-between px-3 sm:px-8">
                <h2 class="text-2xl font-semibold text-white mb-10 text-center">Bekijk hier de trailer:</h2>
                <video class="w-full aspect-video rounded-2xl drop-shadow-lg"
                    src="https://media.taichivoorthuis.nl/assets/video/7_trailer/taiChiVoorThuisTrailer.mp4" controls
                    poster="https://media.taichivoorthuis.nl/assets/thumbnails/07_trailer/thumbnail-00001.png"></video>
            </div>
            <div class="w-full flex flex-col bg-neutral-50 items-center py-6 px-4">
                <div class="flex flex-col gap-3 sm:gap-0 sm:flex-row text-sm items-center sm:justify-between container">
                    <div class="text-center sm:text-left">
                        <p class="font-medium">© Tai Chi voor Thuis 2021</p>
                        <p class="text-neutral-500">Dochteronderneming van De Kleine Weg</p>
                    </div>
                    <div class="text-center sm:text-right">
                        <p class="font-medium">Heb je al een account?</p>
                        <p class="text-green-600 hover:underline" @click="$router.push('/login')">Hier inloggen
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    methods: {
        scrollToElement() {
            const el = this.$refs.section;
            if (el) {
                el.scrollIntoView({ behavior: 'smooth' });
            }
        }
    },
    mounted() {
        document.title = "T'ai Chi voor thuis";
    }
}
</script>