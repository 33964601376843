<template>
    <button @click="openNav = true"
        class="absolute md:hidden bg-green-600 aspect-square text-white h-12 flex items-center justify-center rounded-full top-5 left-5 z-20 shadow-lg">
        <fa-icon icon="bars"></fa-icon>
    </button>
    <div class="absolute z-20 md:z-auto md:static h-screen w-full sm:w-fit sm:min-w-[280px] lg:min-w-[350px] -translate-x-full md:translate-x-0 transition-all"
        :class="{ 'translate-x-0': openNav }">
        <div
            class="navbar h-dvh overscroll-none bg-neutral-100 flex flex-col justify-between md:justify-start p-4 lg:p-8 pt-8 relative border-r border-neutral-200">
            <div class="flex justify-between items-center md:mb-12">
                <the-nav-user-comp></the-nav-user-comp>
                <button @click="openNav = false"
                    class=" bg-green-600/10 aspect-square text-black h-12 flex items-center justify-center rounded-full md:hidden">
                    <fa-icon icon="times"></fa-icon>
                </button>
            </div>
            <nav class="flex flex-col space-y-6">
                <!-- Videos -->
                <router-link
                    class="px-6 py-4 rounded-2xl hover:bg-green-600/5 hover:text-green-700 transition-colors flex items-center gap-3 justify-center sm:justify-start"
                    :class="{ 'bg-green-600/10 text-green-700': $route.path == '/home' }" to="/home" exact><fa-icon
                        icon="play" class="mr-2 md:mr-3" />Video's</router-link>
                <!-- Saved videos -->
                <router-link v-if="getSubValid !== false"
                    class="px-6 py-4 rounded-2xl hover:bg-green-600/5 hover:text-green-700 transition-colors flex items-center gap-3 justify-center sm:justify-start"
                    :class="{ 'bg-green-600/10 text-green-700': $route.path == '/saved' }" to="/saved" exact><fa-icon
                        icon="heart" class="mr-2 md:mr-3" />Favorieten</router-link>
                <!-- Contact -->
                <router-link
                    class="px-6 py-4 rounded-2xl hover:bg-green-600/5 hover:text-green-700 transition-colors flex items-center gap-3 justify-center sm:justify-start"
                    :class="{ 'bg-green-600/10 text-green-700': $route.path == '/contact' }" to="/contact"
                    exact><fa-icon icon="comment" class="mr-2 md:mr-3" />
                    Contact</router-link>
                <!-- Account -->
                <router-link
                    class="px-6 py-4 rounded-2xl hover:bg-green-600/5 hover:text-green-700 transition-colors flex items-center gap-3 justify-center sm:justify-start"
                    :class="{ 'bg-green-600/10 text-green-700': $route.path == '/account' }" to="/account"
                    exact><fa-icon icon="user" class="mr-2 md:mr-3" />Acccount</router-link>
                <!-- Users list -->
                <router-link v-if="GetUserIsAdmin"
                    class="px-6 py-4 rounded-2xl hover:bg-yellow-600/5 hover:text-yellow-700 transition-colors flex items-center gap-3 justify-center sm:justify-start"
                    :class="{ 'bg-yellow-600/10 text-amber-600': $route.path == '/users' }" to="/users" exact><fa-icon
                        icon="users" class="mr-2 md:mr-3" />Gebruikers<span
                        class="w-2 h-2 rounded-full bg-amber-400 "></span></router-link>
                <!-- Logout (mobile) -->
                <router-link
                    class="px-6 py-4 rounded-2xl hover:bg-red-600/5 text-red-700  transition-colors flex items-center gap-3 justify-center sm:justify-start"
                    to="/logout" exact><fa-icon icon="sign-out-alt" class="mr-2 md:mr-3" />Uitloggen</router-link>
            </nav>
            <div class="md:mt-auto">
                <the-tracker-comp></the-tracker-comp>
            </div>
        </div>
    </div>
    <transition name="fade">
        <div v-if="openNav"
            class="wall hidden sm:block md:hidden bg-black/60 fixed top-0 left-0 w-screen h-screen z-10 ">
        </div>
    </transition>
</template>
<script>
import TheNavUserComp from './TheNavUserComp.vue';
import TheTrackerComp from './TheTrackerComp.vue';


export default {
    components: {
        TheNavUserComp,
        TheTrackerComp
    },
    data() {
        return {
            openNav: false,
        }
    },
    computed: {
        GetUserIsAdmin() {
            return this.$store.getters.getUserIsAdmin
        },
        getSubValid() {
            return this.$store.getters.getSubValid
        }
    }

}
</script>