<template>
    <div v-if="getNextCat" class="container px-4 py-5 md:px-8 lg:px-24 lg:py-8">
        <p class="font-semibold text-sm lg:text-base">Volgende video's:</p>
        <Splide class="w-full pt-5 lg:pt-8 relative" :options="options" aria-label="My Favorite Images">
            <SplideSlide v-for="video in getVideos" :key="video">
                <the-next-up-thumb :video="video" :category_id="cat"></the-next-up-thumb>
            </SplideSlide>
            <SplideSlide>
                <div @click="goToNextCat(getNextCat)"
                    class="bg-neutral-100 w-full flex flex-col justify-center items-center aspect-video rounded-2xl text-xs lg:text-sm font-semibold cursor-pointer hover:bg-green-500/10 transition-colors">
                    <p>Volgende categorie<fa-icon icon="chevron-right" class="ml-2 h-3 inline" /></p>

                    <p class="font-normal text-neutral-600 mt-1">{{ getNextCat.name }}</p>
                </div>
            </SplideSlide>

        </Splide>
    </div>
</template>
<script>
import TheNextUpThumb from './TheNextUpThumb.vue'
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';

export default {
    props: ['cat', 'id'],
    components: { TheNextUpThumb, Splide, SplideSlide },
    data() {
        return {
            options: {
                rewind: true,
                perPage: 5,
                gap: '16px',
                arrows: false,
                pagination: false,
                padding: {
                    right: 120
                },
                breakpoints: {
                    1280: {
                        perPage: 4,
                        gap: "12px"
                    },
                    1024: {
                        perPage: 3
                    },
                    640: {
                        perPage: 1,

                    }
                }
            }
        }
    },
    methods: {
        goToNextCat(cat) {
            let url = '/play/' + cat.id + '/0'
            this.$router.push(url)
        }
    },
    computed: {
        getVideos() {
            let videoIndex = this.$store.getters.getVideoIndex
            let filtered = videoIndex.find(indexCategory => {
                return indexCategory.id === this.cat
            })

            let videos = filtered.videos
            let result = []
            let i = parseInt(this.$route.params.id) + 1

            for (i; i < videos.length; i++) {
                result.push(videos[i])
            }

            return result
        },
        getNextCat() {
            if (this.$store.getters.getVideoIndex) {
                let catList = []
                let videoIndex = this.$store.getters.getVideoIndex
                videoIndex.forEach(cat => {
                    catList.push(cat.id)
                });

                let nextCatId = parseInt(catList.indexOf(this.cat)) + 1
                let nextCat = videoIndex[nextCatId]

                return nextCat
            }
            return ''
        }
    }
}
</script>