<template>
    <div class="h-dvh w-screen fixed z-20 flex items-end md:items-center justify-center">
        <div class="wall bg-black/50 z-20 w-full h-full absolute cursor-pointer" @click="$emit('close')"></div>
        <div class="bg-white rounded-t-2xl md:rounded-2xl p-5 sm:p-7 max-w-md flex flex-col z-30 drop-shadow-xl">
            <div>
                <div class="flex items-center justify-between mb-3">

                    <h2 class="font-semibold text-lg"><fa-icon icon="key" class="mr-2" />Wachtwoord
                        <span class="hidden sm:inline">gebruiker</span>
                        resetten
                    </h2>
                    <fa-icon @click="$emit('close')" icon="times" class="text-lg cursor-pointer" />
                </div>
                <p class="text-sm text-neutral-500 mb-6 pt-2 leading-6">
                    Je staat op het punt om het wachtwoord van gebruiker: <span
                        class="font-semibold text-red-600 bg-red-600/10 p-0.5 px-3 rounded-full text-nowrap  ">
                        {{ user.name + ' ' + user.surname }}
                    </span> te resetten. De gebruiker ontvang hierover een e-mail, waarmee de gebruiker het wachtwoord
                    kan aanpassen. Weet je zeker dat je dit wilt doen?</p>
                <div class="grid grid-cols-2 gap-4">

                    <button @click="$emit('close')"
                        class="col-span-1  py-3 px-6 rounded-lg mb-2 bg-green-600 text-white font-semibold text-sm hover:bg-green-700 transition-colors">Annuleren</button>
                    <button @click="resetPass"
                        class="col-span-1  py-3 px-6 rounded-lg mb-2 border border-neutral-500 text-neutral-700 font-semibold text-sm hover:bg-red-600 hover:text-white hover:border-red-600 transition-colors">Resetten</button>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { projectAuth } from '../../../firebaseConfig';


export default {
    props: ['user'],
    emits: ['close'],
    methods: {
        async resetPass() {
            await projectAuth.sendPasswordResetEmail(this.user.email)
            this.$emit('close')
        }
    }
}
</script>
